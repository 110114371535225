import styled from "styled-components";
import { palette } from "styled-theme";
import { transition, borderRadius } from "../../settings/style-util";
import WithDirection from "../../settings/withDirection";

const SidebarWrapper = styled.div`
  .isomorphicSidebar {
    z-index: 1000;
    // background: ${palette("secondary", 0)};
    background: #0074a7;
    padding-top: 20px;
    width: 60px;
    flex: 0 0 240px;

    @media only screen and (max-width: 767px) {
      width: 60px !important;
      flex: 0 0 60px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 60px;
        min-width: 60px !important;
        max-width: 60px !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      // height: 70px;
      // background: rgba(0, 0, 0, 0.3);
      // margin: 0;
      // padding: 0 10px;
      // text-align: center;
      // overflow: hidden;
      // ${borderRadius()};

      // h3 {
      //   a {
      //     font-size: 32px;
      //     font-weight: 800;
      //     line-height: 63px;
      //     letter-spacing: 3px;
      //     text-transform: uppercase;
      //     color: ${palette("error", 1)};
      //     display: block;
      //     text-decoration: none;
      //   }
      // }
      // img{
      //   width: 100px;
      // }

      padding: 11px;
      height: 70px;
      background: #fff;
      margin: 0;
      text-align: center;
      overflow: hidden;
      ${borderRadius()};

      h3 {
        a {
          font-size: 27px;
          font-weight: 500;
          letter-spacing: 0;
        }
      }
      img{
        width: 40px;
      }
    }
    }

    // &.ant-layout-sider-collapsed {
    //   .isoLogoWrapper {
    //     padding: 0;

    //     h3 {
    //       a {
    //         font-size: 27px;
    //         font-weight: 500;
    //         letter-spacing: 0;
    //       }
    //     }
    //     img{
    //       width: 70px;
    //     }
    //   }
    // }

    .isoDashboardMenu {
      padding-top: 0px;
      padding-bottom: 35px;
      background: #0074a7;

      a {
        text-decoration: none;
        font-weight: 400;
      }

      .ant-menu-item {
        width: 100%;
        height: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 19px !important;
      }

      .isoMenuHolder {
        display: flex;
        align-items: center;
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }

      i {
        font-size: 19px;
        color: inherit;
        margin: ${(props) =>
          props["data-rtl"] === "rtl" ? "0 0 0 30px" : "0 30px 0 0"};
        width: 18px;
        ${transition()};
        .sideBarIcon{
          width: 25px;
          height: 25px;
          display:block;
          margin: 10px 0px 10px 0px;
        }
        .sideBarIconActive{
          display: none;
        }
      }

      .nav-text {
        font-size: 14px;
        color: inherit;
        font-weight: 400;
        ${transition()};
      }

      .ant-menu-item-selected {
        background-color: #fff !important;
        .anticon {
          color: #fff;
        }

        i {
          color: #fff;
        }

        .nav-text {
          color: #fff;
        }
        .sideBarIcon{
          display: none;
        }
        .sideBarIconActive{
          display: block;
          width: 25px;
          height: 25px;
          margin: 10px 0px 10px 0px;
        }
      }

      > li {
        &:hover {
          i,
          .nav-text {
            color: #ffffff;
          }
          // .sideBarIcon{
          //   display: none;
          // }
          // .sideBarIconActive{
          //   display: block;
          //   width: 25px;
          //   height: 25px;
          //   margin: 10px 0px 10px 0px;
          // }
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${palette("secondary", 5)};
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 19px;

        > span {
          display: flex;
          align-items: center;
        }

        &:after {
          content: '\f123';
          font-family: 'Ionicons' !important;
          font-size: 16px;
          color: inherit;
          left: ${(props) => (props["data-rtl"] === "rtl" ? "16px" : "auto")};
          right: ${(props) => (props["data-rtl"] === "rtl" ? "auto" : "16px")};
          ${transition()};
        }

        &:hover {
          &:after {
            color: #ffffff;
          }
        }
      }

      .ant-menu-item ant-tooltip-open .ant-menu-item-active{
          display: none;
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${(props) =>
            props["data-rtl"] === "rtl" ? "0px !important" : "74px !important"};
          padding-right: ${(props) =>
            props["data-rtl"] === "rtl" ? "74px !important" : "0px !important"};
          font-size: 13px;
          font-weight: 400;
          color: inherit;
          ${transition()};

          &:hover {
            color: #ffffff;
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        background-color: transparent !important;
      }
    }


    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline >  {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
  }
`;

export default WithDirection(SidebarWrapper);
