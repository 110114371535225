const actions = {
  FETCH_GRADES: 'FETCH_GRADES',
  FETCH_GRADES_SUCCESS: 'FETCH_GRADES_SUCCESS',
  FETCH_GRADE_SUBJECT_SUCCESS: 'FETCH_GRADE_SUBJECT_SUCCESS',
  SAVE_GRADE: 'SAVE_GRADE',
  DELETE_GRADE: 'DELETE_GRADE',
  RESET: "RESET",
  fetchGrades: (spinHandler) => ({
    type: actions.FETCH_GRADES,
    spinHandler
  }),
  saveGrade: (payload, gradeId, cb) => ({
    type: actions.SAVE_GRADE,
    payload,
    gradeId,
    cb    
  }),
  deleteGrade: (payload, gradeId, spinHandler) => ({
    type: actions.DELETE_GRADE,
    payload,
    gradeId,
    spinHandler
  }),
  reset: () => ({
    type: actions.RESET
  }),
};
export default actions;
