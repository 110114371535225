const actions = {
  FETCH_CONFIG_ZOOM: "FETCH_CONFIG_ZOOM",
  FETCH_CONFIG_SUCCESS: "FETCH_CONFIG_SUCCESS",
  FETCH_USER_LIST_ZOOM: "FETCH_USER_LIST_ZOOM",
  FETCH_USER_LIST_ZOOM_SUCCESS: "FETCH_USER_LIST_ZOOM_SUCCESS",
  SAVE_ZOOM_MEETING: "SAVE_ZOOM_MEETING",

  fetchConfig: (callBack) => ({
    type: actions.FETCH_CONFIG_ZOOM,
    callBack,
  }),
  fetchConfigSuccess: (payload) => ({
    type: actions.FETCH_CONFIG_SUCCESS,
    payload,
  }),
  fetchUserList: (payload, callBack) => ({
    type: actions.FETCH_USER_LIST_ZOOM,
    payload,
    callBack,
  }),
  fetchUserListSuccess: (payload) => ({
    type: actions.FETCH_USER_LIST_ZOOM_SUCCESS,
    payload,
  }),
  saveZoomMeeting: (payload, callBack) => ({
    type: actions.SAVE_ZOOM_MEETING,
    payload,
    callBack,
  }),
};
export default actions;
