const actions = {
  GET_ALL_SURVEYS: "GET_ALL_SURVEYS",
  GET_ALL_SURVEYS_SUCCESS: "GET_ALL_SURVEYS_SUCCESS",
  UPLOAD_SURVEY_DETAILS: "UPLOAD_SURVEY_DETAILS",
  UPLOAD_SURVEY_DETAILS_SUCCESS: "UPLOAD_SURVEY_DETAILS_SUCCESS",
  EDIT_SURVEY_DETAILS: "EDIT_SURVEY_DETAILS",
  EDIT_SURVEY_DETAILS_SUCCESS: "EDIT_SURVEY_DETAILS_SUCCESS",
  UPLOAD_SURVEY_ASSERTION_FILE: "UPLOAD_SURVEY_ASSERTION_FILE",
  UPLOAD_SURVEY_ASSERTION_FILE_SUCCESS: "UPLOAD_SURVEY_ASSERTION_FILE_SUCCESS",
  EDIT_ASSERTION_FILE: "EDIT_ASSERTION_FILE",
  DELETE_SURVEY: "DELETE_SURVEY",
  SET_SURVEY_LOADING: "SET_SURVEY_LOADING",
  SET_SURVEY_ERROR: "SET_SURVEY_ERROR",
  GET_SURVEY_REPORT: "GET_SURVEY_REPORT",
  SET_SURVEY_REPORT: "SET_SURVEY_REPORT",
  GET_ALL_SCHOOLS_SURVEY: "GET_ALL_SCHOOLS_SURVEY",
  GET_ALL_SCHOOLS_SURVEY_SUCCESS: "GET_ALL_SCHOOLS_SURVEY_SUCCESS",

  getAllSurvey: (spinHandler) => ({
    type: actions.GET_ALL_SURVEYS,
    spinHandler,
  }),
  getAllSubjects: (spinHandler) => ({
    type: actions.GET_ALL_SUBJECTS,
    spinHandler,
  }),
  getAllClasses: (spinHandler) => ({
    type: actions.GET_ALL_CLASSES,
    spinHandler,
  }),
  uploadSurveyDetails: (payload, callBack) => ({
    type: actions.UPLOAD_SURVEY_DETAILS,
    payload,
    callBack,
  }),
  editSurveyDetails: (payload, cb) => ({
    type: actions.EDIT_SURVEY_DETAILS,
    payload,
    cb,
  }),
  uploadAssertionFile: (payload) => ({
    type: actions.UPLOAD_SURVEY_ASSERTION_FILE,
    payload,
  }),
  deleteSurvey: (payload, spinHandler) => ({
    type: actions.DELETE_SURVEY,
    payload,
    spinHandler,
  }),
  getReport: (payload) => ({
    type: actions.GET_SURVEY_REPORT,
    payload,
  }),
  getAllSchools: (spinHandler) => ({
    type: actions.GET_ALL_SCHOOLS_SURVEY,
    spinHandler,
  }),
};
export default actions;
