const actions = {
	IMPORT: "IMPORT",
	IMPORT_SUCCESS: "IMPORT_SUCCESS",
	ENCRYPT: "ENCRYPT",
	ENCRYPT_DONE: "ENCRYPT_DONE",
	SET_DEO: "SET_DEO",
	SET_DEO_DONE: "SET_DEO_DONE",
	FETCH_DOE: "FETCH_DOE",
	FETCH_DOE_DONE: "FETCH_DOE_DONE",
	RESET: "RESET",
	UPDATE_SETTINGS: "UPDATE_SETTINGS",
	FETCH_SETTINGS: "FETCH_SETTINGS",
	FETCH_CONFIGS: "FETCH_CONFIGS",
	FETCH_CONFIGS_SUCCESS: "FETCH_CONFIGS_SUCCESS",
	FETCH_FIELDS: "FETCH_FIELDS",
	FETCH_FIELDS_RESULT: "FETCH_FIELDS_RESULT",
	FETCH_ADMIN_SETTINGS: "FETCH_ADMIN_SETTINGS",
	FETCH_ADMIN_SETTINGS_SUCCESS: "FETCH_ADMIN_SETTINGS_SUCCESS",
	UPDATE_ADMIN_SETTINGS: "UPDATE_ADMIN_SETTINGS",

	importData: (payload, spinHandler) => ({
		type: actions.IMPORT,
		payload,
		spinHandler,
	}),
	encrypt: (payload, spinHandler) => ({
		type: actions.ENCRYPT,
		payload,
		spinHandler,
	}),
	saveDeo: (payload, spinHandler) => ({
		type: actions.SET_DEO,
		payload,
		spinHandler,
	}),
	fetchDoe: (spinHandler) => ({
		type: actions.FETCH_DOE,
		spinHandler,
	}),
	reset: () => ({
		type: actions.RESET,
	}),
	fetchConfigs: () => ({
		type: actions.FETCH_CONFIGS,
	}),
	fetchSettings: () => ({
		type: actions.FETCH_SETTINGS,
	}),
	fetchForm: (payload) => ({
		type: actions.FETCH_FIELDS,
		payload,
	}),
	updateSettings: (payload) => ({
		type: actions.UPDATE_SETTINGS,
		payload,
	}),
	fetchAdminSetting: () => ({
		type: actions.FETCH_ADMIN_SETTINGS,
	}),
	updateAdminSettings: (payload) => ({
		type: actions.UPDATE_ADMIN_SETTINGS,
		payload,
	}),
};
export default actions;
