import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  schools: null,
  grades: null,
  roles: null,
  users: null,
  loading: false,
  notifications: [],
  isCompleted: false,
});

export default function notificationReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_NOTI_CONFIGS_SUCCESS:
      return state
        .set("schools", action.schools)
        .set("grades", action.grades)
        .set("roles", action.roles);
    case actions.SEARCH_USERS_SUCCESS:
      return state.set("users", action.users);
    case actions.SET_LOADING:
      return state.set("loading", action.loading);
    case actions.FETCH_NOTIFICATIONS_SUCCESS:
      return state
        .set("notifications", action.notifications)
        .set("isCompleted", false);
    case actions.SET_COMPLETED:
      return state.set("isCompleted", true);
    case actions.RESET:
      return state.set("devices", null);
    default:
      return state;
  }
}
