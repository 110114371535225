import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  courses: null,
  teachers: null,
  classroomTeachers: null,
  students: null,
  decryptedPath: null,
  key: null,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_COURSES_SUCCESS:
      return state.set("courses", action.courses);
    case actions.FETCH_TEACHERS_SUCCESS:
      return state.set("teachers", action.teachers);
    case actions.FETCH_COURSE_DETAILS_SUCCESS:
      return state.set("course", action.course);
    case actions.ADD_EXISTING_COURSE_SUCCESS:
      return state.set("course", action.course);
    case actions.EDIT_SINGLE_COURSE_SUCCESS:
      return state.set("course", action.course);
    case actions.DELETE_SINGLE_COURSE_FILE_SUCCESS:
      return state.set("course", action.course);
    case actions.FETCH_CLASSROOM_STUDENTS_SUCCESS:
      return state.set("students", action.students);
    case actions.FETCH_CLASSROOM_TEACHERS_SUCCESS:
      return state.set("classroomTeachers", action.classroomTeachers);
    case actions.GET_FILE_SUCCESS:
      return state.set("decryptedPath", action.decryptedPath);
    case actions.SET_FILTER:
      return state.set("key", action.key);
    case actions.RESET:
      return state.set("courses", null);
    default:
      return state;
  }
}
