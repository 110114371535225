import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import actionsClassroom from "../../redux/MyClassroom/actions";
import Notification from "../../components/notification";

export function* fetchClassroomAdmins() {
  yield takeEvery(actions.FETCH_CLASSROOM_ADMIN, function*(action) {
    try {
      const resp = yield call(API.classroomAdmin.list);
      yield put({
        type: actions.FETCH_CLASSROOM_ADMIN_SUCCESS,
        classroomAdmins: resp.data.data
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveClassroomAdmin() {
  yield takeEvery(actions.SAVE_CLASSROOM_ADMIN, function*(action) {
    try {
      let message;
      let data = new FormData();
      if (action.payload.icon && action.payload.icon.originFileObj) {
        data.append("icon", action.payload.icon.originFileObj);
      }
      data.append("name", action.payload.name);
      if (action.payload.school != "") {
        data.append("school", action.payload.school);
      }
      // data.append("course", JSON.stringify(action.payload.course));
      data.append("course", action.payload.course);
      data.append("teachers", JSON.stringify(action.payload.teachers));
      if (action.payload.type) {
        data.append("type", action.payload.type);
      } else {
        data.append("type", "classroom");
      }
      data.append(
        "students",
        (action.payload.students || action.payload.studentsFileList) &&
          (action.payload.studentsFileList
            ? action.payload.studentsFileList[0].originFileObj
            : JSON.stringify(action.payload.students))
      );
      if (action.classroom_id != undefined) {
        yield call(API.classroomAdmin.update, data, action.classroom_id);
        message = "Classroom admin updated successfully";
      } else {
        yield call(API.classroomAdmin.add, data);
        message = "Classroom admin created successfully";
      }
      const classroomAdmins = yield call(API.classroomAdmin.list, data);
      yield put({
        type: actions.FETCH_CLASSROOM_ADMIN_SUCCESS,
        classroomAdmins: classroomAdmins.data.data
      });
      yield call(action.cb);
      Notification("success", message);
    } catch (err) {
      yield call(action.cb, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteClassroomStudents() {
  yield takeEvery(actions.DELETE_CLASSROOM_STUDENTS, function*(action) {
    try {
      let message;
      let data = new FormData();
      if (action.payload.icon && action.payload.icon.originFileObj) {
        data.append("icon", action.payload.icon.originFileObj);
      }
      data.append("name", action.payload.name);
      if (action.payload.school != "") {
        data.append("school", action.payload.school);
      }
      // data.append("course", JSON.stringify(action.payload.course));
      data.append("course", action.payload.course);
      data.append("teachers", JSON.stringify(action.payload.teachers));
      data.append(
        "students",
        JSON.stringify(
          (action.payload.students || action.payload.studentsFileList) &&
            (action.payload.studentsFileList != undefined
              ? action.payload.studentsFileList[0].originFileObj
              : action.payload.students)
        )
      );
      if (action.classroom_id != undefined) {
        yield call(API.classroomAdmin.update, data, action.classroom_id);
        message = "Student Deleted Successfully";
      } else {
        yield call(API.classroomAdmin.add, data);
        message = "Classroom admin created successfully";
      }
      const respClass = yield call(
        API.myClassroom.listSingleClassroom,
        action.classroom_id
      );
      yield put({
        type: actionsClassroom.FETCH_SINGLE_CLASSROOM_SUCCESS,
        singleClassroom: respClass.data.classroom
      });
      yield call(action.cb);
      Notification("success", message);
    } catch (err) {
      yield call(action.cb, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteClassroomAdmin() {
  yield takeEvery(actions.DELETE_CLASSROOM_ADMIN, function*(action) {
    try {
      yield call(API.classroomAdmin.delete, action.classroom_id);
      const classroomAdmins = yield call(API.classroomAdmin.list);
      yield put({
        type: actions.FETCH_CLASSROOM_ADMIN_SUCCESS,
        classroomAdmins: classroomAdmins.data.data
      });
      Notification("success", "Classroom has been deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchClassroomAdmins),
    fork(saveClassroomAdmin),
    fork(deleteClassroomStudents),
    fork(deleteClassroomAdmin)
  ]);
}
