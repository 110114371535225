/**
 * User selectors
 */

import { createSelector } from "reselect";

const selectGroups = (state) => state.Group;

const listAllGroups = () =>
  createSelector(selectGroups, (groupState) => groupState.get("groups"));

const getConfigs = () =>
  createSelector(selectGroups, (groupState) => groupState.get("configs"));

const showGroupLoading = () =>
  createSelector(selectGroups, (groupState) => groupState.get("loading"));

const listUsers = () =>
  createSelector(selectGroups, (groupState) => groupState.get("users"));

const getCompleteStatus = () =>
  createSelector(selectGroups, (groupState) => groupState.get("completed"));

const getGroupError = () =>
  createSelector(selectGroups, (groupState) => groupState.get("error"));

const getHistory = () =>
  createSelector(selectGroups, (groupState) => groupState.get("history"));

const getSearchKey = () =>
  createSelector(selectGroups, (groupState) => groupState.get("key"));

const getTotal = () =>
  createSelector(selectGroups, (groupState) => groupState.get("total"));

const getGroups = () =>
  createSelector(
    listAllGroups(),
    getSearchKey(),
    (allGroups, key) =>
      allGroups &&
      allGroups.filter((group) => {
        key = key ? key.toLowerCase() : "";
        let val = "";
        if (key) {
          val =
            group &&
            (group.name.toLowerCase().indexOf(key) > -1 ||
              (group.address &&
                group.address.toLowerCase().indexOf(key) > -1) ||
              (group.email && group.email.toLowerCase().indexOf(key) > -1) ||
              (group.contact && group.contact.toString().indexOf(key) > -1));
        } else {
          val = true;
        }
        return val;
      })
  );

export {
  getGroups,
  listAllGroups,
  listUsers,
  showGroupLoading,
  getGroupError,
  getCompleteStatus,
  getHistory,
  getConfigs,
  getTotal,
};
