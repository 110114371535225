import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  health: null,
  types: null,
  areas: null,
  schools: null,
  loading: false,
  tickect_type: null,
  tickect_area: null,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_HEALTH:
      return state.set("loading", true);
    case actions.FETCH_HEALTH_SEARCH:
      return state.set("loading", true);

    case actions.FETCH_HEALTH_SUCCESS:
      return state.set("health", action.data).set("loading", false);

    case actions.FETCH_CATEGORY_SUCCESS:
      return state.set("types", action.types).set("areas", action.areas);
    case actions.TYPE_AREA_SUCCESS:
      return state
        .set("tickect_type", action.types)
        .set("tickect_area", action.areas);
    case actions.HEALTH_TOGGLE:
      return state.set("loading", true);
    case actions.FETCH_SCHOOL_SUCCESS:
      return state.set("schools", action.schools);

    case actions.HEALTH_TOGGLE_SUCCESS:
      return state.set("loading", false);

    case actions.SET_LOADING:
      return state.set("loading", action.loading);

    default:
      return state;
  }
}
