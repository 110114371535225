import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
	data: null,
	file: null,
	doeLogo: null,
	doeUrl: null,
	loading: false,
	successStatus: "",
	formFields: [],
	adminSettings: [],
});

export default function importReducer(state = initState, action) {
  switch (action.type) {
		case actions.IMPORT_SUCCESS:
			return state.set("data", action.data);
		case actions.ENCRYPT_DONE:
			return state.set("file", action.file);
		case actions.SET_DEO_DONE:
		case actions.FETCH_DOE_DONE:
			return state.set("doe", action.doe);
		case actions.RESET:
			return state
				.set("data", null)
				.set("file", null)
				.set("doeLogo", null)
				.set("doeUrl", null);
		case actions.FETCH_CONFIGS_SUCCESS:
			return state.set("schools", action.schools);
		case actions.UPDATE_SETTINGS:
			return state.set("loading", true).set("successStatus", false);
		case actions.FETCH_FIELDS_RESULT:
			return state.set("formFields", action.payload).set("loading", false);
		case actions.FETCH_ADMIN_SETTINGS_SUCCESS:
			return state.set("adminSettings", action.payload).set("loading", false);
		case actions.FETCH_FIELDS:
			return state.set("loading", true);
		default:
			return state;
  }
}
