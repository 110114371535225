import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  listPsm: [],
  psmLoading: false,
  loading: false,
  roles: [],
  grades: [],
  schools: [],
  subjects: [],
  exportData: [],
  userList: null,
  completed: false,
});

export default function psmReducer(state = initState, action) {
  switch (action.type) {
    case actions.PSM_LOADER:
      return state.set("psmLoading", action.loading);

    case actions.SET_LOADING:
      return state.set("loading", action.loading);

    case actions.FETCH_PSM_CONFIG_SUCCESS:
      return state
        .set("roles", action.roles)
        .set("grades", action.grades)
        .set("schools", action.schools)
        .set("subjects", action.subjects);

    case actions.FETCH_PSM_EXPORT:
      return state.set("loading", true).set("exportData", []);

    case actions.FETCH_PSM_EXPORT_SUCCESS:
      return state.set("loading", false).set("exportData", action.payload);

    case actions.FETCH_USERLIST:
      return state.set("userList", null).set("completed", false);

    case actions.FETCH_USERLIST_SUCCESS:
      return state.set("userList", action.users);

    case actions.SAVE_PSM_SUCCESS:
      return state.set("completed", true);

    case actions.RESET:
      return state.set("psm", null);

    case actions.FETCH_PSM_LIST:
      return state.set("loading", true);

    case actions.FETCH_PSM_LIST_SUCCESS:
      return state.set("listPsm", action.data).set("loading", false);

    default:
      return state;
  }
}
