const actions = {
  FETCH_COURSES: "FETCH_COURSES",
  FETCH_COURSES_SUCCESS: "FETCH_COURSES_SUCCESS",
  FETCH_COURSE_DETAILS: "FETCH_COURSE_DETAILS",
  FETCH_COURSE_DETAILS_SUCCESS: "FETCH_COURSE_DETAILS_SUCCESS",
  SAVE_COURSE: "SAVE_COURSE",
  DELETE_COURSE: "DELETE_COURSE",
  EDIT_SINGLE_COURSE: "EDIT_SINGLE_COURSE",
  EDIT_SINGLE_COURSE_SUCCESS: "EDIT_SINGLE_COURSE_SUCCESS",
  ADD_EXISTING_COURSE: "ADD_EXISTING_COURSE",
  ADD_EXISTING_COURSE_SUCCESS: "ADD_EXISTING_COURSE_SUCCESS",
  DELETE_SINGLE_COURSE_FILE: "DELETE_SINGLE_COURSE_FILE",
  DELETE_SINGLE_COURSE_FILE_SUCCESS: "DELETE_SINGLE_COURSE_FILE_SUCCESS",
  FETCH_TEACHERS: "FETCH_TEACHERS",
  FETCH_TEACHERS_SUCCESS: "FETCH_TEACHERS_SUCCESS",
  ADD_BOOK_VERSION: "ADD_BOOK_VERSION",
  ADD_BOOK_VERSION_SUCCESS: "ADD_BOOK_VERSION_SUCCESS",
  GET_FILE: "GET_FILE",
  GET_FILE_SUCCESS: "GET_FILE_SUCCESS",
  FETCH_CLASSROOM_STUDENTS: "FETCH_CLASSROOM_STUDENTS",
  FETCH_CLASSROOM_STUDENTS_SUCCESS: "FETCH_CLASSROOM_STUDENTS_SUCCESS",
  FETCH_CLASSROOM_TEACHERS: "FETCH_CLASSROOM_TEACHERS",
  FETCH_CLASSROOM_TEACHERS: "FETCH_CLASSROOM_TEACHERS",
  FETCH_CLASSROOM_TEACHERS_SUCCESS: "FETCH_CLASSROOM_TEACHERS_SUCCESS",
  SET_FILTER: "SET_FILTER",
  RESET: "RESET",
  FETCH_LESSON: "FETCH_LESSON",
  GET_PDF_SUCCESS: "GET_PDF_SUCCESS",
  DRAG_LESSONS_COURSE: "DRAG_LESSONS_COURSE",
  DRAG_LESSONS_SUCCESS: "DRAG_LESSONS_SUCCESS",
  fetchCourses: (spinHandler) => ({
    type: actions.FETCH_COURSES,
    spinHandler,
  }),
  fetchLesson: (query, urlCallBack) => {
    return {
      type: actions.FETCH_LESSON,
      query,
      urlCallBack,
    };
  },
  addBookFile: (payload, bookId, courseId, spinHandler) => ({
    type: actions.ADD_BOOK_VERSION,
    payload,
    bookId,
    courseId,
    spinHandler,
  }),
  fetchTeachers: (spinHandler) => ({
    type: actions.FETCH_TEACHERS,
    spinHandler,
  }),
  fetchSingleCourse: (courseId, spinHandler) => ({
    type: actions.FETCH_COURSE_DETAILS,
    courseId,
    spinHandler,
  }),
  saveCourse: (payload, courseId, cb) => ({
    type: actions.SAVE_COURSE,
    payload,
    courseId,
    cb,
  }),
  deleteCourse: (payload, courseId, spinHandler) => ({
    type: actions.DELETE_COURSE,
    payload,
    courseId,
    spinHandler,
  }),
  editSingleCourse: (courseId, payload, spinHandler) => ({
    type: actions.EDIT_SINGLE_COURSE,
    courseId,
    payload,
    spinHandler,
  }),
  addExistingCourse: (payload, courseId, spinHandler) => ({
    type: actions.ADD_EXISTING_COURSE,
    payload,
    courseId,
    spinHandler,
  }),
  dragLessons: (payload, courseId, spinHandler) => ({
    type: actions.DRAG_LESSONS_COURSE,
    payload,
    courseId,
    spinHandler,
  }),
  deleteSingleCourse: (payload, courseId, resourceId, spinHandler) => ({
    type: actions.DELETE_SINGLE_COURSE_FILE,
    payload,
    courseId,
    resourceId,
    spinHandler,
  }),
  getFile: (payload, spinHandler) => ({
    type: actions.GET_FILE,
    payload,
    spinHandler,
  }),
  fetchClassroomStudents: (school_id, spinHandler) => ({
    type: actions.FETCH_CLASSROOM_STUDENTS,
    school_id,
    spinHandler,
  }),
  fetchClassroomTeachers: (school_id, spinHandler) => ({
    type: actions.FETCH_CLASSROOM_TEACHERS,
    school_id,
    spinHandler,
  }),
  setFilter: (key) => ({
    type: actions.SET_FILTER,
    key,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
};
export default actions;
