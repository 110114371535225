const actions = {
  FETCH_GRAPH_DATA: "FETCH_GRAPH_DATA",
  FETCH_GRAPH_DATA_SUCCESS: "FETCH_GRAPH_DATA_SUCCESS",
  FETCH_METABASE: "FETCH_METABASE",
  FETCH_METABASE_SUCCESS: "FETCH_METABASE_SUCCESS",
  FETCH_CONFIGS_DASHBOARD: "FETCH_CONFIGS_DASHBOARD",
  FETCH_CONFIGS_DASHBOARD_SUCCESS: "FETCH_CONFIGS_DASHBOARD_SUCCESS",
  FETCH_CLASSROOM_CONFIGS_DASHBOARD: "FETCH_CLASSROOM_CONFIGS_DASHBOARD",
  FETCH_CLASSROOM_CONFIGS_DASHBOARD_SUCCESS:
    "FETCH_CLASSROOM_CONFIGS_DASHBOARD_SUCCESS",
  UPLOAD_SCHOOL_ADMIN_AVATAR: "UPLOAD_SCHOOL_ADMIN_AVATAR",
  REMOVE_ADMIN_AVATAR: "REMOVE_ADMIN_AVATAR",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  FETCH_LOGGED_USER_DATA: "FETCH_LOGGED_USER_DATA",
  FETCH_LOGGED_USER_DATA_SUCCESS: "FETCH_LOGGED_USER_DATA_SUCCESS",
  FETCH_LESSON_DATA: "FETCH_LESSON_DATA",
  FETCH_LESSON_DATA_SUCCESS: "FETCH_LESSON_DATA_SUCCESS",
  CHANGE_CLASSROOM_VALUE: "CHANGE_CLASSROOM_VALUE",
  CHANGE_CLASSROOM_VALUE_SUCCESS: "CHANGE_CLASSROOM_VALUE_SUCCESS",
  FETCH_TOP_HASHTAGS: "FETCH_TOP_HASHTAGS",
  FETCH_TOP_HASHTAGS_SUCCESS: "FETCH_TOP_HASHTAGS_SUCCESS",
  FETCH_TOP_LESSON_HASHTAGS: "FETCH_TOP_LESSON_HASHTAGS",
  FETCH_TOP_LESSON_HASHTAGS_SUCCESS: "FETCH_TOP_LESSON_HASHTAGS_SUCCESS",
  FETCH_TOP_WEB_PLATFORMS: "FETCH_TOP_WEB_PLATFORMS",
  FETCH_TOP_WEB_PLATFORMS_SUCCESS: "FETCH_TOP_WEB_PLATFORMS_SUCCESS",
  FETCH_COLLABORATIONS: "FETCH_COLLABORATIONS",
  FETCH_COLLABORATIONS_SUCCESS: "FETCH_COLLABORATIONS_SUCCESS",
  FETCH_CLUB_PROGRESS: "FETCH_CLUB_PROGRESS",
  FETCH_CLUB_PROGRESS_SUCCESS: "FETCH_CLUB_PROGRESS_SUCCESS",
  FETCH_CLASSROOM_PROGRESS: "FETCH_CLASSROOM_PROGRESS",
  FETCH_CLASSROOM_PROGRESS_SUCCESS: "FETCH_CLASSROOM_PROGRESS_SUCCESS",
  FETCH_MCQPROGRESS: "FETCH_MCQPROGRESS",
  FETCH_MCQPROGRESS_SUCCES: "FETCH_MCQPROGRESS_SUCCES",
  FETCH_MY_TOP_HASHTAGS: "FETCH_MY_TOP_HASHTAGS",
  FETCH_MY_TOP_HASHTAGS_SUCCESS: "FETCH_MY_TOP_HASHTAGS_SUCCESS",
  fetchData: (payload, spinHandler) => ({
    type: actions.FETCH_GRAPH_DATA,
    spinHandler,
    payload,
  }),
  fetchConfigs: () => ({
    type: actions.FETCH_CONFIGS_DASHBOARD,
  }),
  fetchMetabase: (spinHandler, payload) => ({
    type: actions.FETCH_METABASE,
    spinHandler,
    payload,
  }),
  uploadAvatar: (payload, userId, spinHandler) => ({
    type: actions.UPLOAD_SCHOOL_ADMIN_AVATAR,
    payload,
    userId,
    spinHandler,
  }),
  removeAvatar: (payload, userId, cb) => ({
    type: actions.REMOVE_ADMIN_AVATAR,
    payload,
    userId,
    cb,
  }),
  fetchClassroomConfigs: (spinHandler) => ({
    type: actions.FETCH_CLASSROOM_CONFIGS_DASHBOARD,
    spinHandler,
  }),
  changePassword: (payload, userId, loader) => ({
    type: actions.CHANGE_PASSWORD,
    payload,
    userId,
    loader,
  }),
  fetchLoggedInUser: (dateset, spinHandler) => ({
    type: actions.FETCH_LOGGED_USER_DATA,
    dateset,
    spinHandler,
  }),
  fetchLessonData: (dateset, spinHandler) => ({
    type: actions.FETCH_LESSON_DATA,
    dateset,
    spinHandler,
  }),
  classroomFilter: (classroomvalue, spinHandler) => ({
    type: actions.CHANGE_CLASSROOM_VALUE,
    classroomvalue,
    spinHandler,
  }),
  fetchTopHashtags: (dateset, spinHandler) => ({
    type: actions.FETCH_TOP_HASHTAGS,
    dateset,
    spinHandler,
  }),

  fetchTopLessonHashtags: (dateset, spinHandler) => ({
    type: actions.FETCH_TOP_LESSON_HASHTAGS,
    dateset,
    spinHandler,
  }),
  fetchMyTopHashtags: (dateset, spinHandler) => ({
    type: actions.FETCH_MY_TOP_HASHTAGS,
    dateset,
    spinHandler,
  }),
  fetchTopWebPlatforms: (dateset, spinHandler) => ({
    type: actions.FETCH_TOP_WEB_PLATFORMS,
    dateset,
    spinHandler,
  }),
  fetchCollaborations: (dateset, spinHandler) => ({
    type: actions.FETCH_COLLABORATIONS,
    dateset,
    spinHandler,
  }),

  fetchMcqProgress: (dateset, spinHandler) => ({
    type: actions.FETCH_MCQPROGRESS,
    dateset,
    spinHandler,
  }),
  fetchClubProgress: (dateset, spinHandler) => ({
    type: actions.FETCH_CLUB_PROGRESS,
    dateset,
    spinHandler,
  }),

  fetchClassroomProgress: (dateset, spinHandler) => ({
    type: actions.FETCH_CLASSROOM_PROGRESS,
    dateset,
    spinHandler,
  }),
};
export default actions;
