import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout, Tooltip } from "antd";
import Scrollbars from "../../components/utility/customScrollBar.js";
import Menu from "../../components/uielements/menu";
import SidebarWrapper from "./sidebar.style";
import find from "lodash/find";
import appActions from "../../redux/app/actions";
import { getCurrentTheme } from "../ThemeSwitcher/config";
import { themeConfig } from "../../settings";
import menu from "./menuConfig";
import "./sidebar.css";

const { Sider } = Layout;
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;

const renderData = (
  list,
  blockedList,
  role = "",
  classrooms,
  hideDashboard = false
) => {
  const listCopy = { ...list };

  blockedList.forEach((el) => {
    const name =
      el.menu &&
      el.menu.displayName &&
      el.menu.displayName
        .replace(/[^a-zA-Z ]/g, "")
        .toLowerCase()
        .split(" ")
        .join("");
    if (listCopy[name]) {
      delete listCopy[name];
    }
    if (name === "appversion") delete listCopy["chromeversion"];
  });

  if (role === "ADMIN") {
    delete listCopy["admin"];
    delete listCopy["roles"];
    // delete listCopy["devices"];
  }

  if (classrooms && classrooms.length === 0) {
    delete listCopy["myclassroom"];
  }
  if (hideDashboard) {
    delete listCopy["dashboard"];
  }

  return Object.keys(listCopy).map((el) => listCopy[el]);
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleChangeCurrent = this.handleChangeCurrent.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  handleChangeCurrent(e, key) {
    let menuKey = key.split("/").pop();
    this.props.changeCurrent([menuKey]);
  }

  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };

  onSelectedKeys = (curr) => {
    const path = window.location.pathname.split("/")[2];
    const menuList = Object.keys(menu?.content).map((el) => menu?.content[el]);
    const item = find(menuList, ["path", `/${path}`]);
    if (item?.key) return item?.key;
    else if (menu?.content[curr] && menu?.content[curr]?.key)
      return menu?.content[curr]?.key;

    return curr;
  };
  render() {
    const { url, userRole, app, blockList, classrooms, profile } = this.props;

    const sideBarList = renderData(
      menu["content"],
      blockList,
      userRole,
      classrooms,
      profile?.hideAdminDashboard
    );

    const userMenu = sideBarList;

    const customizedTheme = getCurrentTheme("sidebarTheme", themeConfig.theme);
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = (event) => {
      return;
    };
    const onMouseLeave = () => {
      return;
    };
    const scrollheight = app.height;
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };
    const setSelectedKeys =
      app.current.length > 0
        ? this.onSelectedKeys(app.current)
        : [userMenu[0].key];
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          // collapsible={true}
          collapsed={false}
          width="60px"
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Scrollbars style={{ height: scrollheight - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              defaultSelectedKeys={[""]}
              selectedKeys={"menu" + setSelectedKeys}
              onOpenChange={this.onOpenChange}
              className="isoDashboardMenu"
            >
              {userMenu &&
                userMenu.map((menu) => {
                  return (
                    <Menu.Item key={"menu" + menu.key}>
                      <Tooltip
                        placement="right"
                        title={menu.label}
                        className="tooltip"
                      >
                        <Link to={`${url}${menu.path}`}>
                          <span className="isoMenuHolder" style={submenuColor}>
                            <i>
                              <img src={menu.icon} className="sideBarIcon" />
                              <img
                                src={menu.iconActive}
                                className="sideBarIconActive"
                              />
                            </i>
                          </span>
                        </Link>
                      </Tooltip>
                    </Menu.Item>
                  );
                })}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    app: {
      collapsed: state.App.collapsed,
      view: state.App.view,
      openDrawer: state.App.openDrawer,
      height: state.App.height,
      openKeys: state.App.openKeys,
      current: state.App.current,
    },
    userRole: state.Auth.getIn(["profile"])["role"]["handle"],
    blockList: state.Auth.getIn(["profile"])["role"]["blockedMenus"],
    classrooms: state.Auth.getIn(["profile"])["classrooms"],
    profile: state.Auth.getIn(["profile"]),
  }),
  {
    toggleOpenDrawer,
    changeOpenKeys,
    changeCurrent,
    toggleCollapsed,
  }
)(Sidebar);
