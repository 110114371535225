const actions = {
  SAVE_TYPE: "SAVE_TYPE",
  DELETE_TYPE: "DELETE_TYPE",
  saveType: (payload, ticketTypeId, cb) => ({
    type: actions.SAVE_TYPE,
    payload,
    ticketTypeId,
    cb,
  }),
  deleteType: (ticketTypeId, spinHandler) => ({
    type: actions.DELETE_TYPE,
    ticketTypeId,
    spinHandler,
  }),
};
export default actions;
