const actions = {
  FETCH_USERLIST: "FETCH_USERLIST",
  FETCH_USERLIST_SUCCESS: "FETCH_USERLIST_SUCCESS",

  FETCH_PSM_CONFIG: "FETCH_PSM_CONFIG",
  FETCH_PSM_CONFIG_SUCCESS: "FETCH_PSM_CONFIG_SUCCESS",

  SAVE_PSM: "SAVE_PSM",
  SAVE_PSM_SUCCESS: "SAVE_PSM_SUCCESS",
  DELETE_PSM: "DELETE_PSM",
  PSM_LOADER: "PSM_LOADER",
  RESET: "RESET",

  SET_LOADING: "SET_PSM_LOADING",
  SET_LOADING_SUCCESS: "SET_PSM_LOADING_SUCCESS",

  FETCH_PSM_LIST: "FETCH_PSM_LIST",
  FETCH_PSM_LIST_SUCCESS: "FETCH_PSM_LIST_SUCCESS",

  FETCH_PSM_EXPORT: "FETCH_PSM_EXPORT",
  FETCH_PSM_EXPORT_SUCCESS: "FETCH_PSM_EXPORT_SUCCESS",

  fetchPsmList: (payload) => ({
    type: actions.FETCH_PSM_LIST,
    payload,
  }),

  fetchExportContent: (payload, cb) => ({
    type: actions.FETCH_PSM_EXPORT,
    payload,
    cb,
  }),

  fetchUserList: (payload) => ({
    type: actions.FETCH_USERLIST,
    payload,
  }),
  // savePsm: (psmList, grade, role, school, subject, userList) => ({
  //   type: actions.SAVE_PSM,
  //   psmList,
  //   grade,
  //   role,
  //   school,
  //   subject,
  //   userList,
  // }),
  savePsm: (psmList, school, userList, classroom, cb) => ({
    type: actions.SAVE_PSM,
    psmList,
    school,
    userList,
    classroom,
    cb,
  }),
  deletePsm: (pasmId, spinHandler) => ({
    type: actions.DELETE_PSM,
    pasmId,
    spinHandler,
  }),
  fetchPsmConfig: () => ({
    type: actions.FETCH_PSM_CONFIG,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
};
export default actions;
