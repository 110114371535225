import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  classroomAdmins: null,
  key: null,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_CLASSROOM_ADMIN_SUCCESS:
      return state.set("classroomAdmins", action.classroomAdmins);
    case actions.FETCH_CLASSROOM_ADMIN_DETAILS_SUCCESS:
      return state.set("classroomAdmin", action.classroomAdmin);
    case actions.EDIT_SINGLE_CLASSROOM_ADMIN_SUCCESS:
      return state.set("classroomAdmin", action.classroomAdmin);
    case actions.DELETE_CLASSROOM_ADMIN_SUCCESS:
      return state.set("classroomAdmin", action.classroomAdmin);
    case actions.SET_FILTER:
      return state.set("key", action.key);
    case actions.RESET:
      return state.set("classroomAdmins", null);
    default:
      return state;
  }
}
