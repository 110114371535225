import notification from "./feedback/notification";

const createNotification = (type, title, description) => {
  try {
    let message = title
      .trim()
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(" ")
      .split("_")
      .join(" ");
    notification[type]({
      message,
      description,
    });
  } catch (err) {
    console.log(err);
  }
};
export default createNotification;
