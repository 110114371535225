const actions = {
  UPLOAD_REWARDS_FILE: "UPLOAD_REWARDS_FILE",
  SET_REWARDS_LOADING: "SET_REWARDS_LOADING",
  SET_REWARDS_ERROR: "SET_REWARDS_ERROR",
  UPLOAD_REWARDS_FILE_SUCCESS: "UPLOAD_REWARDS_FILE_SUCCESS",
  RESET: "RESET",
  REQUEST_REWARDS: "REQUEST_REWARDS",
  REQUEST_REWARDS_SUCCESS: "REQUEST_REWARDS_SUCCESS",
  FETCH_SCHOOLS: "FETCH_SCHOOLS_REWARDS",
  FETCH_SCHOOLS_SUCCESS: "FETCH_SCHOOLS_SUCCESS",

  uploadRewardFile: (payload, callBack) => ({
    type: actions.UPLOAD_REWARDS_FILE,
    payload,
    callBack,
  }),
  requestRewards: (payload) => ({
    type: actions.REQUEST_REWARDS,
    spinner: payload,
  }),
  fetchSchools: () => ({
    type: actions.FETCH_SCHOOLS,
  }),
};

export default actions;
