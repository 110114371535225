const actions = {
  FETCH_LESSON_DETAILS: "FETCH_LESSON_DETAILS",
  FETCH_LESSON_DETAILS_SUCCESS: "FETCH_LESSON_DETAILS_SUCCESS",
  FETCH_LESSON_DETAILS_ERROR: "FETCH_LESSON_DETAILS_ERROR",
  FETCH_LESSONS: "FETCH_LESSONS",
  FETCH_LESSONS_SUCCESS: "FETCH_LESSONS_SUCCESS",
  SAVE_LESSON: "SAVE_LESSON",
  SAVE_LESSON_SUCCESS: "SAVE_LESSON_SUCCESS",
  SAVE_LESSON_ERROR: "SAVE_LESSON_ERROR",
  DELETE_LESSON: "DELETE_LESSON",
  DELETE_LESSON_SUCCESS: "DELETE_LESSON_SUCCESS",
  DELETE_LESSON_ERROR: "DELETE_LESSON_ERROR",
  FETCH_LIBRARY: "FETCH_LIBRARY",
  FETCH_LIBRARY_SUCCESS: "FETCH_LIBRARY_SUCCESS",
  FETCH_MY_LIBRARY: "FETCH_MY_LIBRARY",
  FETCH_MY_LIBRARY_SUCCESS: "FETCH_MY_LIBRARY_SUCCESS",
  FILE_UPLOAD: "FILE_UPLOAD",
  FILE_UPLOAD_SUCCESS: "FILE_UPLOAD_SUCCESS",
  FILE_UPLOAD_ERROR: "FILE_UPLOAD_ERROR",

  fetchLessons: (payload = {}) => ({
    type: actions.FETCH_LESSONS,
    payload,
  }),
  fetchLibraries: (payload = {}) => ({
    type: actions.FETCH_LIBRARY,
    payload,
  }),
  fetchMyLibraries: (payload = {}) => ({
    type: actions.FETCH_MY_LIBRARY,
    payload,
  }),
  fetchLessonDetails: (id, callBack) => ({
    type: actions.FETCH_LESSON_DETAILS,
    id,
    callBack,
  }),
  saveLesson: (lessonId = null, payload = {}, callBack = undefined) => ({
    type: actions.SAVE_LESSON,
    lessonId,
    payload,
    callBack,
  }),
  deleteLesson: (lessonId = null, callBack = undefined) => ({
    type: actions.DELETE_LESSON,
    lessonId,
    callBack,
  }),
  uploadFile: (payload = {}, callBack = undefined) => ({
    type: actions.FILE_UPLOAD,
    payload,
    callBack,
  }),
};

export default actions;
