import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  surveys: [],
  survey: null,
  key: null,
  assertionID: "",
  loading: false,
  error: "",
  report: [],
  schools: [],
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ALL_SURVEYS_SUCCESS:
      return state.set("surveys", action.surveys).set("assertionID", "");

    case actions.UPLOAD_SURVEY_DETAILS:
      return state.set("assertionID", "");

    case actions.EDIT_SURVEY_DETAILS:
      return state.set("assertionID", "");

    case actions.UPLOAD_SURVEY_DETAILS_SUCCESS:
      return state.set("assertionID", action.assertionID);

    case actions.SET_SURVEY_LOADING:
      return state.set("loading", action.loading);

    case actions.SET_SURVEY_ERROR:
      return state.set("error", action.error);

    case actions.SET_FILTER:
      return state.set("key", action.key);

    case actions.RESET:
      return state
        .set("surveys", [])
        .set("subjects", [])
        .set("grades", [])
        .set("survey", null)
        .set("assertionID", "");
    case actions.SET_SURVEY_REPORT:
      return state.set("report", action.payload.data);
    case actions.GET_ALL_SCHOOLS_SURVEY_SUCCESS:
      return state.set("schools", action.schools);
    default:
      return state;
  }
}
