import { fromJS } from 'immutable';
import actions from './actions';

const initState = fromJS({
  otherApps: null,
  roles: null,
  schools: null,
  grades: null,
  users: null,
  key: null
});

export default function otherAppVersionReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_OTHER_APPS_SUCCESS:
      return state
        .set('otherApps', action.otherApps);
    case actions.FETCH_OTHER_APP_USERS_SUCCESS:
      return state
        .set('users', action.users);
    case actions.FETCH_OTHER_APP_CONFIGS_SUCCESS:
      return state
        .set('roles', action.roles)
        .set('schools', action.schools)
        .set('grades', action.grades);
    case actions.SET_FILTER:
      return state
        .set('key', action.key);
    case actions.RESET:
      return state
        .set('otherApps', null);
    default:
      return state;
  }
}
