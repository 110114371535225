import { fromJS } from 'immutable';
import actions from './actions';

const initState = fromJS({
  grades: null,
  subjects: null,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_GRADES_SUCCESS:
      return state
        .set('grades', action.grades);
    case actions.FETCH_GRADE_SUBJECT_SUCCESS:
      return state
        .set('subjects', action.subjects);
    case actions.RESET:
      return state
        .set('grades', null)
        .set('subjects', null);
    default:
      return state;
  }
}
