import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchSurveys() {
  yield takeEvery(actions.GET_ALL_SURVEYS, function* (action) {
    try {
      const resp = yield call(
        API.survey.list,
        action.payload ? action.payload : null
      );

      if (resp.success) {
        yield put({
          type: actions.GET_ALL_SURVEYS_SUCCESS,
          surveys: resp.data,
          loading: false,
        });
      } else {
        yield put({
          type: actions.SET_SURVEY_LOADING,
          loading: false,
        });
        Notification("error", resp.message);
      }
      if (action.spinHandler) yield call(action.spinHandler);
    } catch (err) {
      yield put({
        type: actions.SET_SURVEY_LOADING,
        loading: false,
      });
      if (err && err.error && err.error.message)
        Notification(
          "error",
          err && err.error ? err.error.message : "Invalid Request"
        );
      if (action.spinHandler) yield call(action.spinHandler);
    }
  });
}

export function* uploadSurveyDetails() {
  yield takeEvery(actions.UPLOAD_SURVEY_DETAILS, function* (action) {
    try {
      let formData = new FormData();
      if (action.payload.file && action.payload.file.length) {
        formData.append("file", action.payload.file[0].originFileObj);
        formData.append("filename", action.payload.file[0].name);
      }
      formData.append("title", action.payload.title);
      formData.append("start", action.payload.start);
      formData.append("instructions", action.payload.instructions);
      if (action.payload.school) {
        formData.append("school", action.payload.school);
      }
      if (action.payload.classroom) {
        formData.append("classroom", action.payload.classroom);
      }

      yield put({ type: actions.SET_SURVEY_LOADING, loading: true });

      const resp = yield call(API.survey.add, formData);

      yield put({ type: actions.SET_SURVEY_LOADING, loading: false });

      if (resp.data) {
        if (resp.data.success) {
          yield put({
            type: actions.UPLOAD_SURVEY_DETAILS_SUCCESS,
            assertionID: resp.data.data,
          });
          yield call(action.callBack);
          Notification("success", resp.data.message);
        } else {
          Notification("error", resp.data.message);
        }
      } else {
        throw Error();
      }
    } catch (err) {
      yield put({ type: actions.SET_SURVEY_LOADING, loading: false });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* uploadAssertionFile() {
  yield takeEvery(actions.UPLOAD_SURVEY_ASSERTION_FILE, function* (action) {
    try {
      let data = new FormData();
      if (action.payload.file.length) {
        data.append("file", action.payload.file[0].originFileObj);
        data.append("filename", action.payload.file[0].name);
        data.append("survayid", action.payload.accessionID);
        yield put({ type: actions.SET_SURVEY_LOADING, loading: true });

        const resp = yield call(API.survey.uploadAssertion, data);

        yield put({ type: actions.SET_SURVEY_LOADING, loading: false });

        if (resp.success) {
          yield put({
            type: actions.UPLOAD_SURVEY_DETAILS_SUCCESS,
            assertionID: "success",
          });
          Notification("success", resp.message);
        } else {
          Notification("error", resp.message);
        }
      }
    } catch (e) {
      yield put({ type: actions.SET_SURVEY_LOADING, loading: false });
    }
  });
}

export function* editSurvey() {
  yield takeEvery(actions.EDIT_SURVEY_DETAILS, function* (action) {
    try {
      yield put({ type: actions.SET_SURVEY_LOADING, loading: true });

      const resp = yield call(API.survey.update, action.payload);

      yield put({ type: actions.SET_SURVEY_LOADING, loading: false });

      if (resp.data) {
        if (resp.data.success) {
          Notification("success", resp.data.message);
          yield call(action.cb, true);
        } else {
          yield call(action.cb, false);
          Notification("error", resp.data.message);
        }
      } else {
        yield call(action.cb, false);
        throw Error();
      }
    } catch (e) {
      yield put({ type: actions.SET_SURVEY_LOADING, loading: false });
    }
  });
}

export function* deleteSurvey() {
  yield takeEvery(actions.DELETE_SURVEY, function* (action) {
    try {
      const resp = yield call(API.survey.delete, action.payload);
      if (resp.success) {
        yield put({
          type: actions.GET_ALL_SURVEYS,
        });
        Notification("success", resp.message);
        yield call(action.spinHandler, true);
      } else {
        Notification("error", resp.message);
        yield call(action.spinHandler, false);
      }
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchReport() {
  yield takeEvery(actions.GET_SURVEY_REPORT, function* ({ payload }) {
    try {
      const resp = yield call(API.survey.getReport, payload.id);
      if (resp.success) {
        yield put({
          type: actions.SET_SURVEY_REPORT,
          payload: { data: resp.data },
        });
      } else {
        yield put({
          type: actions.SET_SURVEY_REPORT,
          payload: { data: [] },
        });
        Notification("error", resp.message);
      }
      yield call(payload.callBack);
    } catch (err) {
      yield call(payload.callBack);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSchools() {
  yield takeEvery(actions.GET_ALL_SCHOOLS_SURVEY, function* (action) {
    try {
      const resp = yield call(API.schools.list);
      yield put({
        type: actions.GET_ALL_SCHOOLS_SURVEY_SUCCESS,
        schools: resp.data.schools,
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchSurveys)]);
  yield all([fork(uploadSurveyDetails)]);
  yield all([fork(uploadAssertionFile)]);
  yield all([fork(editSurvey)]);
  yield all([fork(deleteSurvey)]);
  yield all([fork(fetchReport)]);
  yield all([fork(fetchSchools)]);
}
