import { Result } from "antd";
import React from "react";

const UnAuthorized = () => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Result
        status="403"
        title="401"
        subTitle="Sorry, You Are Not Authorized to Access This Page."
      />
    </div>
  );
};

export default UnAuthorized;
