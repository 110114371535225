import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";
import authActions from "../../redux/auth/actions";
import { adminDefaultAvatar } from "../../helpers/utility";

export function* fetchData() {
  yield takeEvery(actions.FETCH_GRAPH_DATA, function*(action) {
    try {
      const resp = yield call(API.dashboard.list1, action.payload);
      if (resp) {
        yield put({
          type: actions.FETCH_GRAPH_DATA_SUCCESS,
          data: resp.data.metabase.siteURL,
        });
      }
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      // Notification(
      //   "error",
      //   err && err.error
      //     ? err.error.message
      //     : "Session timed out. Please login again"
      // );
    }
  });
}

export function* fetchMetaData() {
  yield takeEvery(actions.FETCH_METABASE, function*(action) {
    try {
      console.log(action);
      const resp = yield call(API.dashboard.list, action.payload);
      if (resp) {
        console.log("ffff", resp.data.metabase.siteURL);
        yield put({
          type: actions.FETCH_METABASE_SUCCESS,
          data: resp.data.metabase.siteURL,
        });
      }
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification("error", err && err.error && "Error");
    }
  });
}

export function* fetchLoggedUserData() {
  yield takeEvery(actions.FETCH_LOGGED_USER_DATA, function*(action) {
    try {
      console.log("action");
      const resp = yield call(API.dashboard.listLoggedUser, action.dateset);

      if (resp) {
        console.log("ffff", resp);
        yield put({
          type: actions.FETCH_LOGGED_USER_DATA_SUCCESS,
          data: resp.data,
        });
      }
      yield call(action.spinHandler, false);
    } catch (err) {
      // yield call(action.spinHandler, false);
      // Notification("error", err && err.error && "Error");
    }
  });
}
export function* fetchClubProgress() {
  yield takeEvery(actions.FETCH_CLUB_PROGRESS, function*(action) {
    try {
      const payload = {};
      if (action?.dateset?.students)
        payload.student = action?.dateset?.students;
      payload.weeks = action?.dateset?.weeks || [];
      const resp = yield call(API.DashboardParent.fetchclubprogress, payload);

      if (resp) {
        console.log("ffff", resp);
        yield put({
          type: actions.FETCH_CLUB_PROGRESS_SUCCESS,
          clubprogressData: resp.data,
        });
      }
      yield call(action.spinHandler, false);
    } catch (err) {
      if (action.spinHandler) yield call(action.spinHandler, false);
      yield put({
        type: actions.FETCH_CLUB_PROGRESS_SUCCESS,
        clubprogressData: [],
      });
      // yield call(action.spinHandler, false);
      // Notification("error", err && err.error && "Error");
    }
  });
}

export function* fetchClassroomProgress() {
  yield takeEvery(actions.FETCH_CLASSROOM_PROGRESS, function*(action) {
    try {
      const resp = yield call(
        API.DashboardParent.fetchclassprogress,
        action.dateset
      );

      if (resp) {
        console.log("ffff", resp);
        yield put({
          type: actions.FETCH_CLASSROOM_PROGRESS_SUCCESS,
          classroomprogressData: resp.data,
        });
      }
      yield call(action.spinHandler, false);
    } catch (err) {
      if (action.spinHandler) yield call(action.spinHandler, false);
      put({
        type: actions.FETCH_CLASSROOM_PROGRESS_SUCCESS,
        classroomprogressData: [],
      });
      // yield call(action.spinHandler, false);
      // Notification("error", err && err.error && "Error");
    }
  });
}

export function* fetchLessonData() {
  yield takeEvery(actions.FETCH_LESSON_DATA, function*(action) {
    try {
      const resp = yield call(API.dashboard.lessons, action.dateset);
      yield put({
        type: actions.FETCH_LESSON_DATA_SUCCESS,
        lessonData: resp.data,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      if (action.spinHandler) yield call(action.spinHandler, false);

      // Notification(
      //   "error",
      //   err && err.error
      //     ? err.error.message
      //     : "Session timed out. Please login again"
      // );
    }
  });
}
//test

export function* classroomFilter() {
  yield takeEvery(actions.CHANGE_CLASSROOM_VALUE, function*(action) {
    try {
      if (action?.classroomvalue?.length > 0) {
        const resp = yield call(API.dashboard.classroom, action.classroomvalue);
        yield put({
          type: actions.CHANGE_CLASSROOM_VALUE_SUCCESS,
          classroomsData: resp?.data?.info || [],
        });
      } else {
        yield put({
          type: actions.CHANGE_CLASSROOM_VALUE_SUCCESS,
          classroomsData: [],
        });
      }
      yield call(action.spinner, false);
    } catch (err) {
      // Notification(
      //   "error",
      //   err && err.error
      //     ? err.error.message
      //     : "Session timed out. Please login again"
      // );
    }
  });
}

export function* fetchTopLessonHashtags() {
  yield takeEvery(actions.FETCH_TOP_LESSON_HASHTAGS, function*(action) {
    try {
      const response = yield call(
        API.DashboardParent.lessonhashtagData,
        action.dateset
      );
      if (response) {
        yield put({
          type: actions.FETCH_TOP_LESSON_HASHTAGS_SUCCESS,
          lessonhashtagData: response.data.topHashtags,
        });
      }
      yield call(action.spinHandler, false);
    } catch (error) {
      if (action.spinHandler) yield call(action.spinHandler, false);
      yield put({
        type: actions.FETCH_TOP_LESSON_HASHTAGS_SUCCESS,
        lessonhashtagData: [],
      });
    }
  });
}
export function* fetchMyTopLessonHashtags() {
  yield takeEvery(actions.FETCH_MY_TOP_HASHTAGS, function*(action) {
    try {
      const response = yield call(
        API.DashboardParent.myTopHashtags,
        action.dateset
      );
      if (response) {
        yield put({
          type: actions.FETCH_MY_TOP_HASHTAGS_SUCCESS,
          myHashtagData: response.data.topHashtags,
        });
      }
      yield call(action.spinHandler, false);
    } catch (error) {
      if (action.spinHandler) yield call(action.spinHandler, false);
      yield put({
        type: actions.FETCH_MY_TOP_HASHTAGS_SUCCESS,
        myHashtagData: [],
      });
    }
  });
}

export function* fetchTopHashtags() {
  yield takeEvery(actions.FETCH_TOP_HASHTAGS, function*(action) {
    try {
      const response = yield call(
        API.DashboardParent.topHashtags,
        action.dateset
      );
      if (response) {
        yield put({
          type: actions.FETCH_TOP_HASHTAGS_SUCCESS,
          hashtagData: response.data.topHashtags,
        });
      }
      yield call(action.spinHandler, false);
    } catch (error) {
      if (action.spinHandler) yield call(action.spinHandler, false);
      put({
        type: actions.FETCH_TOP_HASHTAGS_SUCCESS,
        hashtagData: [],
      });
    }
  });
}
export function* fetchTopWebPlatforms() {
  yield takeEvery(actions.FETCH_TOP_WEB_PLATFORMS, function*(action) {
    try {
      const response = yield call(
        API.DashboardParent.topWebPlatforms,
        action.dateset
      );
      if (response) {
        yield put({
          type: actions.FETCH_TOP_WEB_PLATFORMS_SUCCESS,
          webData: response.data.topWebplatformsNew,
        });
      }
      yield call(action.spinHandler, false);
    } catch (error) {
      if (action.spinHandler) yield call(action.spinHandler, false);
      yield put({
        type: actions.FETCH_TOP_WEB_PLATFORMS_SUCCESS,
        webData: [],
      });
    }
  });
}

export function* fetchCollaborations() {
  yield takeEvery(actions.FETCH_COLLABORATIONS, function*(action) {
    try {
      const response = yield call(
        API.DashboardParent.collaborations,
        action.dateset
      );
      if (response) {
        yield put({
          type: actions.FETCH_COLLABORATIONS_SUCCESS,
          collaborations: response.data.collaborations,
        });
      }
      yield call(action.spinHandler, false);
    } catch (error) {
      yield put({
        type: actions.FETCH_COLLABORATIONS_SUCCESS,
        collaborations: [],
      });
    }
  });
}

export function* fetchMcqProgress() {
  yield takeEvery(actions.FETCH_MCQPROGRESS, function*(action) {
    try {
      const response = yield call(
        API.DashboardParent.McqProgress,
        action.dateset
      );
      if (response) {
        yield put({
          type: actions.FETCH_MCQPROGRESS_SUCCES,
          mcqProgress: response.data.testProgress,
        });
      }
      yield call(action.spinHandler, false);
    } catch (error) {
      if (action.spinHandler) yield call(action.spinHandler, false);
      yield put({
        type: actions.FETCH_MCQPROGRESS_SUCCES,
        mcqProgress: [],
      });
    }
  });
}
export function* fetchConfigs() {
  yield takeEvery(actions.FETCH_CONFIGS_DASHBOARD, function*(action) {
    try {
      const schools = yield call(API.common.schools);
      // const grades = yield call(API.common.grades);
      if (schools /*&& grades*/) {
        yield put({
          type: actions.FETCH_CONFIGS_DASHBOARD_SUCCESS,
          grades: /*grades && grades.data.grades ? grades.data.grades : */ [],
          schools: schools && schools.data.schools ? schools.data.schools : [],
        });
      }
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* updateAvatar() {
  yield takeEvery(actions.UPLOAD_SCHOOL_ADMIN_AVATAR, function*(action) {
    try {
      let resp, message;
      if (action.userId) {
        const data = new FormData();
        if (action.payload.file.originFileObj) {
          data.append("avatar", action.payload.file.originFileObj);
          data.append("userId", action.userId);
          resp = yield call(API.profile.uploadAvatar, data, action.userId);
          message = "Avatar updated successfully";
        }
        yield put({
          type: authActions.UPDATE_PROFILE,
          avatar: resp?.data?.data?.avatar,
        });
        yield call(action.spinHandler, false);
      }
      Notification("success", message);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteAvatar() {
  yield takeEvery(actions.REMOVE_ADMIN_AVATAR, function*(action) {
    try {
      let resp, message;
      if (action?.userId) {
        const data = new FormData();
        data.append("avatar", "");
        data.append("userId", action.userId);
        resp = yield call(API.profile.uploadAvatar, data, action.userId);
        message = "Avatar has been removed";
        yield put({
          type: authActions.UPDATE_PROFILE,
          avatar: adminDefaultAvatar,
        });
      }
      Notification("success", message);
      if (action?.cb) yield call(action.cb, true);
    } catch (err) {
      if (action?.cb) yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* changePassword() {
  yield takeEvery(actions.CHANGE_PASSWORD, function*(action) {
    try {
      let resp, message;
      if (action.userId) {
        resp = yield call(
          API.profile.changePassword,
          action?.payload,
          action.userId
        );
        message = "Password changed successfully";
      }
      Notification("success", message);
      if (action?.loader) yield call(action.loader, true);
    } catch (err) {
      if (action?.loader) yield call(action.loader, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchClassroomConfigs() {
  yield takeEvery(actions.FETCH_CLASSROOM_CONFIGS_DASHBOARD, function*(action) {
    try {
      const classrooms = yield call(API.common.classrooms);
      if (classrooms) {
        yield put({
          type: actions.FETCH_CLASSROOM_CONFIGS_DASHBOARD_SUCCESS,
          classrooms:
            classrooms && classrooms.data.data ? classrooms.data.data : [],
        });
      }
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export default function* rootSaga() {
  yield all([fork(fetchData)]);
  yield all([fork(fetchConfigs)]);
  yield all([fork(fetchClassroomConfigs)]);
  yield all([fork(fetchMetaData)]);
  yield all([fork(fetchLoggedUserData)]);
  yield all([fork(updateAvatar)]);
  yield all([fork(deleteAvatar)]);
  yield all([fork(changePassword)]);
  yield all([fork(fetchLessonData)]);
  yield all([fork(classroomFilter)]);
  yield all([fork(fetchTopHashtags)]);
  yield all([fork(fetchTopLessonHashtags)]);
  yield all([fork(fetchTopWebPlatforms)]);
  yield all([fork(fetchClubProgress)]);
  yield all([fork(fetchClassroomProgress)]);
  yield all([fork(fetchCollaborations)]);
  yield all([fork(fetchMcqProgress)]);
  yield all([fork(fetchMyTopLessonHashtags)]);
}
