import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchOtherAppVersions() {
  yield takeEvery(actions.FETCH_OTHER_APPS, function*(action) {
    try {
      const resp = yield call(API.otherApps.list);
      const users = yield call(API.users.list, action.payload);
      yield put({
        type: actions.FETCH_OTHER_APPS_SUCCESS,
        otherApps: resp.data.apps,
      });
      yield put({
        type: actions.FETCH_OTHER_APP_USERS_SUCCESS,
        users: users && users.data.users ? users.data.users : [],
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* saveOtherAppVersion() {
  yield takeEvery(actions.SAVE_OTHER_APPS, function*(action) {
    try {
      let message;
      let data = new FormData();
      if (action.payload.file && action.payload.file.originFileObj) {
        data.append("file", action.payload.file.originFileObj);
        data.append("name", action.payload.file.name);
      }
      data.append("version", action.payload.version);
      data.append("toAllUsers", action.payload.option == 1 ? true : false);
      if (action.payload.option == 2) {
        data.append("users", JSON.stringify(action.payload.users));
      }
      // if (action.payload.school) {
        data.append("school", action?.payload?.school||"");
      // }
      if (action.payload.classroom) {
        data.append("classroom", action.payload.classroom);
      }
      // if (action.payload.grade) {
      //   data.append("grade", action.payload.grade);
      // }
      // if (action.payload.role) {
      //   data.append("role", action.payload.role);
      // }

      if (action.appId) {
        yield call(API.otherApps.update, data, action.appId);
        message = "App version updated successfully";
      } else {
        yield call(API.otherApps.save, data);
        message = "Chrome App version added successfully";
      }

      const resp = yield call(API.otherApps.list);
      yield put({
        type: actions.FETCH_OTHER_APPS_SUCCESS,
        otherApps: resp.data.apps,
      });
      yield call(action.cb);
      Notification("success", "Chrome App version updated successfully");
    } catch (err) {
      yield call(action.cb, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteOtherAppVersion() {
  yield takeEvery(actions.DELETE_OTHER_APP, function*(action) {
    try {
      yield call(API.otherApps.delete, action.appId);
      const resp = yield call(API.otherApps.list);
      yield put({
        type: actions.FETCH_OTHER_APPS_SUCCESS,
        otherApps: resp.data.apps,
      });
      Notification("success", "Chrome App version deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchConfigs() {
  yield takeEvery(actions.FETCH_OTHER_APP_CONFIGS, function*(action) {
    try {
      const roles = yield call(API.common.roles);
      const schools = yield call(API.common.schools);
      // const grades = yield call(API.common.grades);
      yield put({
        type: actions.FETCH_OTHER_APP_CONFIGS_SUCCESS,
        roles: roles && roles.data.roles ? roles.data.roles : [],
        schools: schools && schools.data.schools ? schools.data.schools : [],
        grades: /*grades && grades.data.grades ? grades.data.grades : */ [],
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchUsers() {
  yield takeEvery(actions.FETCH_OTHER_APP_USERS, function*(action) {
    try {
      const users = yield call(API.users.list, action.payload);
      yield put({
        type: actions.FETCH_OTHER_APP_USERS_SUCCESS,
        users: users && users.data.users ? users.data.users : [],
      });
      yield call(action.spinHandler);
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchOtherAppVersions),
    fork(saveOtherAppVersion),
    fork(deleteOtherAppVersion),
    fork(fetchUsers),
    fork(fetchConfigs),
  ]);
}
