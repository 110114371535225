import { fromJS } from 'immutable';
import actions from './actions';

const initState = fromJS({
  organizations: null,
  organization: null,
  key: null
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_ORGANIZATIONS_SUCCESS:
      return state
        .set('organizations', action.organizations);
    case actions.FETCH_ORGANIZATION_DETAILS_SUCCESS:
      return state
        .set('organization', action.organization);
    case actions.SET_FILTER:
      return state
        .set('key', action.key);
    case actions.RESET:
    return state
      .set('key', null)
      // .set('key', null);
    default:
      return state;
  }
}
