import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  users: null,
  user: null,
  roles: null,
  schools: null,
  courses: null,
  organizations: null,
  grades: null,
  subjects: null,
  key: null,
  teachers: null,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_USERS_ADMIN_SUCCESS:
      return state.set("users", action.users);
    case actions.FETCH_USER_ADMIN_DETAILS_SUCCESS:
      return state.set("user", action.user);
    case actions.FETCH_USER_ADMIN_DETAILS_FAIL:
      return state.set("user", action.user);
    // case actions.FETCH_CONFIGS_SUCCESS:;
    //   return state
    //     .set('roles', action.roles)
    //     .set('schools', action.schools)
    //     .set('subjects', action.subjects)
    //     .set('grades', action.grades);
    case actions.CHANGE_ADMIN_STATUS_SUCCESS:
      const users = Object.assign([], state.get("users"));
      const userInd = users.findIndex((user) => user._id == action.userId);
      if (userInd > -1) {
        users[userInd].isActive = action.isActive;
      }
      return state.set("users", users);
    case actions.SET_FILTER:
      return state.set("key", action.key);
    case actions.RESET:
      return state /*.set("users", null)*/
        .set("user", null);
    case actions.FETCH_ORGANIZATION_SCHOOL_SUCCESS:
      return state.set("schools", action.schools);
    case actions.FETCH_ORGANIZATION_COURSE_SUCCESS:
      return state.set("courses", action.courses);
    case actions.FETCH_ORGANIZATION_SUCCESS:
      return state.set("organizations", action.organizations);
    case actions.FETCH_TEACHER_SUCCESS:
      return state.set("teachers", action.teachers);
    default:
      return state;
  }
}
