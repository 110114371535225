import { all, call, takeEvery, put, fork, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* uploadRewardFile() {
  yield takeEvery(actions.UPLOAD_REWARDS_FILE, function*(action) {
    try {
      let data = new FormData();
      if (action.payload.file.length) {
        data.append("file", action.payload.file[0].originFileObj);
        data.append("filename", action.payload.file[0].name);
        if (action.payload.school) {
          data.append("school", action.payload.school);
        }

        yield put({ type: actions.SET_REWARDS_LOADING, loading: true });

        const resp = yield call(API.rewards.create, data);

        yield put({ type: actions.SET_REWARDS_LOADING, loading: false });

        if (resp.data) {
          if (resp.data.data && resp.data.data.length) {
            yield put({
              type: actions.UPLOAD_REWARDS_FILE_SUCCESS,
              status: "success",
            });
            Notification("success", resp.data.message);
            yield put(actions.requestRewards(action.callBack));
          } else {
            yield put({
              type: actions.UPLOAD_REWARDS_FILE_SUCCESS,
              status: "failure",
            });
            Notification("error", resp.data.message);
          }
        }
      }
    } catch (error) {
      yield put({ type: actions.SET_REWARDS_LOADING, loading: false });
      yield call(action.callBack);
      Notification("error", error.error.message);
    }
  });
}

export function* getRewards() {
  yield takeEvery(actions.REQUEST_REWARDS, function*(action) {
    try {
      const resp = yield call(API.rewards.list, { limit: 1000, offset: 0 });
      if (resp.data) {
        yield put({
          type: actions.REQUEST_REWARDS_SUCCESS,
          rewards: resp.data,
        });
        yield call(action.spinner);
      } else {
        yield call(action.spinner);
        Notification("error", resp.message);
      }
    } catch (err) {
      yield call(action.spinner);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSchools() {
  yield takeEvery(actions.FETCH_SCHOOLS, function*(action) {
    try {
      const resp = yield call(API.common.schools);
      yield put({
        type: actions.FETCH_SCHOOLS_SUCCESS,
        schools: resp.data.schools,
      });
    } catch (err) {
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(uploadRewardFile)]);
  yield all([fork(getRewards)]);
  yield all([fork(fetchSchools)]);
}
