const actions = {
  SAVE_AREA: 'SAVE_AREA',
  DELETE_AREA:'DELETE_AREA',
  saveArea: (payload, ticketAreaId, cb) => ({
    type: actions.SAVE_AREA,
    payload,
    ticketAreaId,
    cb    
  }),
  deleteArea: (ticketAreaId, spinHandler) => ({
    type: actions.DELETE_AREA,
    ticketAreaId,
    spinHandler
  }),
};
export default actions;
