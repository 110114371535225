import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  groups: [],
  users: [],
  configs: {
    schools: null,
    grades: null,
    subjects: null,
  },
  loading: false,
  completed: false,
  error: "",
  history: [],
  key: null,
  total: 0,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.CREATE_GROUP_SUCCESS:
      return state.set("completed", action.completed);

    case actions.FETCH_CONFIGS_SUCCESS:
      return state.set("configs", {
        schools: action.schools,
        subjects: action.subjects,
        grades: action.grades,
      });

    case actions.EDIT_GROUP_SUCCESS:
      return state.set("completed", action.completed);

    case actions.LIST_GROUP_SUCCESS:
      return state
        .set("groups", action.payload)
        .set("completed", false)
        .set("total", action.total);

    case actions.FETCH_USERS_FOR_GROUP:
      return state.set("users", []);

    case actions.FETCH_USERS_FOR_GROUP_SUCCESS:
      return state.set("users", action.payload);

    case actions.GET_CHAT_HISTORY:
      return state.set("history", []);

    case actions.GET_CHAT_HISTORY_SUCCESS:
      return state.set("history", action.payload);

    case actions.SET_LOADING:
      return state.set("loading", action.loading);

    case actions.SET_FILTER:
      return state.set("key", action.key);

    case actions.RESET:
      return state
        .set("surveys", [])
        .set("subjects", [])
        .set("grades", [])
        .set("survey", null)
        .set("assertionID", "");
    default:
      return state;
  }
}
