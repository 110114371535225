const actions = {
  FETCH_OTHER_APPS: "FETCH_OTHER_APPS",
  FETCH_OTHER_APPS_SUCCESS: "FETCH_OTHER_APPS_SUCCESS",
  SAVE_OTHER_APPS: "SAVE_OTHER_APPS",
  DELETE_OTHER_APP: "DELETE_OTHER_APP",
  SET_FILTER: "SET_FILTER",
  FETCH_OTHER_APP_USERS: "FETCH_OTHER_APP_USERS",
  FETCH_OTHER_APP_USERS_SUCCESS: "FETCH_OTHER_APP_USERS_SUCCESS",
  FETCH_OTHER_APP_CONFIGS: "FETCH_OTHER_APP_CONFIGS",
  FETCH_OTHER_APP_CONFIGS_SUCCESS: "FETCH_OTHER_APP_CONFIGS_SUCCESS",
  RESET: "RESET",
  fetchOtherApps: (spinHandler) => ({
    type: actions.FETCH_OTHER_APPS,
    spinHandler,
  }),
  saveOtherApp: (payload, appId, cb) => ({
    type: actions.SAVE_OTHER_APPS,
    payload,
    appId,
    cb,
  }),
  deleteOtherApp: (appId, spinHandler) => ({
    type: actions.DELETE_OTHER_APP,
    appId,
    spinHandler,
  }),
  fetchUsers: (payload, spinHandler) => ({
    type: actions.FETCH_OTHER_APP_USERS,
    payload,
    spinHandler,
  }),
  fetchAppConfigs: () => ({
    type: actions.FETCH_OTHER_APP_CONFIGS,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
};
export default actions;
