import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchTests() {
  yield takeEvery(actions.GET_ALL_MCQ_TESTS, function*(action) {
    try {
      yield put({ type: actions.SET_TABLE_LOADING, loading: true });
      const resp = yield call(API.test.list);
      if (resp.success) {
        yield put({
          type: actions.GET_ALL_MCQ_TESTS_SUCCESS,
          tests: resp.data,
        });
      } else {
        // Notification("error", resp.message);
      }
      yield put({ type: actions.SET_TABLE_LOADING, loading: false });
    } catch (err) {
      yield put({ type: actions.SET_TABLE_LOADING, loading: false });
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSubjects() {
  yield takeEvery(actions.GET_ALL_SUBJECTS, function*(action) {
    try {
      const resp = yield call(API.subjects.list);
      yield put({
        type: actions.GET_ALL_SUBJECTS_SUCCESS,
        subjects: resp.data.subjects,
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchSchools() {
  yield takeEvery(actions.GET_ALL_SCHOOLS, function*(action) {
    try {
      const resp = yield call(API.schools.list);
      yield put({
        type: actions.GET_ALL_SCHOOLS_SUCCESS,
        schools: resp.data.schools,
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchClasses() {
  yield takeEvery(actions.GET_ALL_CLASSES, function*(action) {
    try {
      const resp = yield call(API.grades.list);
      yield put({
        type: actions.GET_ALL_CLASSES_SUCCESS,
        grades: resp.data.grades,
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* uploadTestDetails() {
  yield takeEvery(actions.UPLOAD_TEST_DETAILS, function*(action) {
    try {
      let data = new FormData();
      if (action?.payload?.file?.length || action?.payload?.questions?.length) {
        if (action?.payload?.file?.length) {
          data.append("file", action.payload.file[0].originFileObj);
          data.append("filename", action.payload.file[0].name);
        }
        if (action?.payload?.questions?.length)
          data.append("questions", JSON.stringify(action.payload.questions));
        action.payload.subject
          ? data.append("subject", action.payload.subject)
          : null;
        action.payload.grade
          ? data.append("grade", action.payload.grade)
          : null;
        action.payload.school
          ? data.append("school", action.payload.school)
          : null;
        action.payload.classroom &&
          data.append("classroom", action.payload.classroom);
        data.append("title", action.payload.title);
        data.append("forLesson", action.payload.forLesson);
        if (!action.payload.forLesson) {
          data.append("start", action.payload.start);
          data.append("end", action.payload.end);
        }
        data.append("instructions", action.payload.instructions);

        yield put({ type: actions.SET_MCQ_LOADING, loading: true });

        const resp = yield call(API.test.add, data);

        yield put({ type: actions.SET_MCQ_LOADING, loading: false });

        if (resp.data) {
          if (resp.data.success) {
            yield put({
              type: actions.UPLOAD_TEST_DETAILS_SUCCESS,
            });
            Notification("success", resp.data.message);
          } else {
            Notification("error", resp.data.message);
          }
        } else {
          throw Error();
        }
      }
    } catch (err) {
      yield put({ type: actions.SET_MCQ_LOADING, loading: false });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* uploadAssertionFile() {
  yield takeEvery(actions.UPLOAD_ASSERTION_FILE, function*(action) {
    try {
      let data = new FormData();
      if (action.payload.file.length) {
        data.append("file", action.payload.file[0].originFileObj);
        data.append("filename", action.payload.file[0].name);
        data.append("testid", action.payload.accessionID);

        yield put({ type: actions.SET_MCQ_LOADING, loading: true });

        const resp = yield call(API.test.uploadAssertion, data);

        yield put({ type: actions.SET_MCQ_LOADING, loading: false });

        if (resp.success) {
          yield put({
            type: actions.UPLOAD_TEST_DETAILS_SUCCESS,
          });
          Notification("success", resp.message);
        } else {
          Notification("error", resp.data.message);
        }
      }
    } catch (e) {
      yield put({ type: actions.SET_MCQ_LOADING, loading: false });
    }
  });
}

export function* updateTestDetails() {
  yield takeEvery(actions.EDIT_TEST_DETAILS, function*(action) {
    try {
      yield put({ type: actions.SET_MCQ_LOADING, loading: true });

      const resp = yield call(API.test.update, action.payload);

      yield put({ type: actions.SET_MCQ_LOADING, loading: false });

      if (resp.data) {
        if (resp.data.success) {
          yield put({
            type: actions.UPLOAD_TEST_DETAILS_SUCCESS,
          });
          Notification("success", resp.data.message);
        } else {
          Notification("error", resp.data.message);
        }
      } else {
        throw Error();
      }
    } catch (err) {
      yield put({ type: actions.SET_MCQ_LOADING, loading: false });

      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteTest() {
  yield takeEvery(actions.DELETE_TEST, function*(action) {
    try {
      const resp = yield call(API.test.delete, action.payload);
      if (resp.success) {
        yield put({
          type: actions.GET_ALL_MCQ_TESTS,
        });
        Notification("success", resp.message);
        yield call(action.cb, true);
      } else {
        Notification("error", resp.message);
        yield call(action.cb, false);
      }
    } catch (err) {
      yield call(action.cb, false);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchTestReport() {
  yield takeEvery(actions.GET_TEST_REPORT, function*({ payload }) {
    try {
      const resp = yield call(API.test.getReport, payload.id);
      if (resp.success) {
        yield put({
          type: actions.SET_TEST_REPORT,
          payload: { data: resp.data },
        });
      }
      yield call(payload.callBack);
    } catch (err) {
      yield call(payload.callBack);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* fetchLessonReports() {
  yield takeEvery(actions.GET_LESSON_REPORT, function*(action) {
    try {
      yield put({ type: actions.SET_MCQ_LOADING, loading: true });
      const resp = yield call(API.test.report, action?.payload);

      if (resp.success) {
        yield put({
          type: actions.GET_LESSON_REPORT_SUCCESS,
          reports: resp.data,
        });
      } else {
        // Notification("error", resp.message);
      }
      yield put({ type: actions.SET_MCQ_LOADING, loading: false });
    } catch (err) {
      yield put({ type: actions.SET_MCQ_LOADING, loading: false });
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchTests)]);
  yield all([fork(fetchSubjects)]);
  yield all([fork(fetchSchools)]);
  yield all([fork(fetchClasses)]);
  yield all([fork(uploadTestDetails)]);
  yield all([fork(updateTestDetails)]);
  yield all([fork(deleteTest)]);
  yield all([fork(uploadAssertionFile)]);
  yield all([fork(fetchTestReport)]);
  yield all([fork(fetchLessonReports)]);
}
