import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  roleList: [],
  menuItems: [],
  assertionID: "",
  error: "",
});

export default function roleReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_MENU_ITEMS:
      return state.set("menuItems", action.data);
    case actions.SET_ROLES:
      return state.set("roleList", action.data);
    default:
      return state;
  }
}
