import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchData() {
  yield takeEvery(actions.GET_SCHOOL, function*() {
    try {
      const resp = yield call(API.schools.list);
      if (resp) {
        yield put({
          type: actions.GET_SCHOOL_SUCCESS,
          data: resp.data.schools,
        });
      }
    } catch (err) {
      Notification("error", err && err.error && "Error");
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchData)]);
}
