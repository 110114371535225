import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  data: [],
  schools: null,
  classrooms: null,
  grades: null,
  loggedUserData: null,
  lessonData: [],
  classroomsData: [],
  hashtagData: [],
  lessonhashtagData: [],
  collaborations: [],
  webData: [],
  clubprogressData: [],
  classroomprogressData: [],
  mcqProgress: [],
  myHashtagData: [],
});

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_GRAPH_DATA_SUCCESS:
      return state.set("data", action.data);
    case action.FETCH_METABASE_SUCCESS:
      return state.set("metabase"), action.data;
    case actions.FETCH_CONFIGS_DASHBOARD_SUCCESS:
      return state.set("schools", action.schools).set("grades", action.grades);
    case actions.FETCH_CLASSROOM_CONFIGS_DASHBOARD_SUCCESS:
      return state.set("classrooms", action.classrooms);
    case actions.FETCH_LOGGED_USER_DATA_SUCCESS:
      return state.set("loggedUserData", action.data);
    case actions.FETCH_LESSON_DATA_SUCCESS:
      return state.set("lessonData", action.lessonData);
    case actions.CHANGE_CLASSROOM_VALUE_SUCCESS:
      return state.set("classroomData", action.classroomsData);
    case actions.FETCH_TOP_HASHTAGS_SUCCESS:
      return state.set("hashtagData", action.hashtagData);
    case actions.FETCH_TOP_LESSON_HASHTAGS_SUCCESS:
      return state.set("lessonhashtagData", action.lessonhashtagData);
    case actions.FETCH_TOP_WEB_PLATFORMS_SUCCESS:
      return state.set("webData", action.webData);
    case actions.FETCH_COLLABORATIONS_SUCCESS:
      return state.set("collaborations", action.collaborations);
    case actions.FETCH_MCQPROGRESS_SUCCES:
      return state.set("mcqProgress", action.mcqProgress);
    case actions.FETCH_CLUB_PROGRESS_SUCCESS:
      return state.set("clubprogressData", action.clubprogressData);
    case actions.FETCH_CLASSROOM_PROGRESS_SUCCESS:
      return state.set("classroomprogressData", action.classroomprogressData);
    case actions.FETCH_MY_TOP_HASHTAGS_SUCCESS:
      return state.set("myHashtagData", action.myHashtagData);
    default:
      return state;
  }
}
