import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";
import { find } from "lodash-es";

export function* getLessons() {
  yield takeEvery(actions.FETCH_LESSONS, function*(action) {
    try {
      const resp = yield call(API.lessonBuilder.get, action.payload);
      yield put({
        type: actions.FETCH_LESSONS_SUCCESS,
        data: resp.data.data,
      });
    } catch (err) {
      //   yield put({
      //     type: actions.FETCH_LESSONS_SUCCESS,
      //     data: resp,
      //   });
    }
  });
}
export function* getLibrary() {
  yield takeEvery(actions.FETCH_LIBRARY, function*(action) {
    try {
      const resp = yield call(API.lessonBuilder.getLibrary, action.payload);
      yield put({
        type: actions.FETCH_LIBRARY_SUCCESS,
        data: (resp.data && resp.data.resources) || [],
      });
    } catch (err) {
      //   yield put({
      //     type: actions.FETCH_LESSONS_SUCCESS,
      //     data: resp,
      //   });
    }
  });
}

export function* getMyLibrary() {
  yield takeEvery(actions.FETCH_MY_LIBRARY, function*(action) {
    try {
      const resp = yield call(API.lessonBuilder.getMyLibrary, action.payload);
      yield put({
        type: actions.FETCH_MY_LIBRARY_SUCCESS,
        data: (resp.data && resp.data.resources) || [],
      });
    } catch (err) {
      //   yield put({
      //     type: actions.FETCH_LESSONS_SUCCESS,
      //     data: resp,
      //   });
    }
  });
}

export function* fetchSingleLesson() {
  yield takeEvery(actions.FETCH_LESSON_DETAILS, function*(action) {
    try {
      const resp = yield call(API.lessonBuilder.getDetails, action.id);
      yield put({
        type: actions.FETCH_LESSON_DETAILS_SUCCESS,
        data: resp.data.data,
      });
      if (action.callBack) yield call(action.callBack, true);
    } catch (err) {
      console.log("errr--->", err);
      if (action.callBack) yield call(action.callBack, false);
      yield put({
        type: actions.FETCH_LESSON_DETAILS_ERROR,
      });
    }
  });
}
export function* storeLesson() {
  yield takeEvery(actions.SAVE_LESSON, function*(action) {
    try {
      let res = null,
        message;
      if (action.lessonId)
        res = yield call(
          API.lessonBuilder.update,
          action.lessonId,
          action.payload
        );
      else res = yield call(API.lessonBuilder.save, action.payload);
      if (action?.payload?.completed) message = "Lesson Completed Successfully";
      else if (action.lessonId) message = "Lesson Updated Successfully";
      else message = "Lesson Saved Successfully";
      yield put({
        type: actions.SAVE_LESSON_SUCCESS,
        data: res?.data?.lessonData,
      });
      Notification("success", message);
      if (action.callBack) {
        yield call(
          action.callBack,
          action.lessonId
            ? true
            : { status: true, id: res?.data?.lessonData?._id }
        );
      }
    } catch (err) {
      if (action.callBack) {
        yield call(action.callBack, false);
      }
      if (err.error.data)
        yield put({
          type: actions.SAVE_LESSON_ERROR,
          errors: err.error.data,
        });
      else
        yield put({
          type: actions.SAVE_LESSON_ERROR,
          errors: null,
        });
      let desc = "";
      try {
        let errors = err?.error?.data || [];
        const param = find(errors, ["param", "title"]);
        desc = param ? "Please Fill Title" : "";
      } catch (e) {
        desc = "";
      }
      if (desc == "") {
        try {
          if (err?.error?.code == "TEST_MISSING")
            desc = "Please Add Test Details to Complete This Lesson.";
        } catch (e) {
          desc = "";
        }
      }
      if (desc == "") {
        try {
          if (
            (err?.error?.data || "")
              .toLowerCase()
              .includes("CSV".toLocaleLowerCase())
          )
            desc = err?.error?.data || "";
        } catch (e) {
          desc = "";
        }
      }
      if (err.error.message) Notification("error", err.error.message, desc);
    }
  });
}
export function* deleteLesson() {
  yield takeEvery(actions.DELETE_LESSON, function*(action) {
    try {
      let res = null,
        message;
      if (action.lessonId)
        res = yield call(API.lessonBuilder.delete, action.lessonId);
      message = "Lesson Deleted Successfully";
      yield put({
        type: actions.DELETE_LESSON_SUCCESS,
        data: null,
      });
      Notification("success", message);
      if (action.callBack) {
        yield call(action.callBack, true);
      }
    } catch (err) {
      if (action.callBack) {
        yield call(action.callBack, false);
      }
      if (err.error.data)
        yield put({
          type: actions.DELETE_LESSON_ERROR,
          errors: err.error.data,
        });
      if (err.error.message) Notification("error", err.error.message);
    }
  });
}
export function* uploadFile() {
  yield takeEvery(actions.FILE_UPLOAD, function*(action) {
    try {
      let data = new FormData();
      if (action.payload.file) {
        data.append(
          "file",
          action.payload.file.originFileObj
            ? action.payload.file.originFileObj
            : ""
        );
      }
      // if (action.payload.type) data.append("type", "image");
      const res = yield call(API.lessonBuilder.fileUpload, data);
      yield put({
        type: actions.FILE_UPLOAD_SUCCESS,
        data: null,
      });
      Notification("success", "File Uploaded Successfully");
      if (action.callBack) {
        yield call(action.callBack, res);
      }
    } catch (err) {
      if (action.callBack) {
        yield call(action.callBack, false);
      }
      // if (err.error.data)
      yield put({
        type: actions.FILE_UPLOAD_ERROR,
        // errors: err.error.data,
      });
      if (err?.error?.message) Notification("error", err?.error?.message);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchSingleLesson),
    fork(getLessons),
    fork(storeLesson),
    fork(deleteLesson),
    fork(getLibrary),
    fork(getMyLibrary),
    fork(uploadFile),
  ]);
}
