import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  schools: [],
  roles: [],
  grades: [],
  subjects: [],
  users: [],
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_CONFIG_SUCCESS:
      return state
        .set("schools", action.payload.schools)
        .set("roles", action.payload.roles)
        .set("grades", action.payload.grades)
        .set("subjects", action.payload.subjects);
    case actions.FETCH_USER_LIST_ZOOM_SUCCESS:
      return state.set("users", action.payload);
    default:
      return state;
  }
}
