import { fromJS } from 'immutable';
import actions from './actions';

const initState = fromJS({
  schools: null,
  school: null,
  key: null
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_SCHOOLS_SUCCESS:
      return state
        .set('schools', action.schools);
    case actions.FETCH_SCHOOL_DETAILS_SUCCESS:
      return state
        .set('school', action.school);
    case actions.SET_FILTER:
      return state
        .set('key', action.key);
    case actions.RESET:
    return state
      .set('schools', null)
      .set('school', null);
    default:
      return state;
  }
}
