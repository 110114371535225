import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Row, Col, Tooltip } from "antd";
import Popover from "../../components/uielements/popover";
import userpic from "../../image/avatar.png";
import authAction from "../../redux/auth/actions";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { getProfile } from "../../redux/auth/selectors";
import { serverBaseUrl } from "../../helpers/utility";
const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }

  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    let { user } = this.props;
    // console.log(user)
    const userImage =
      user && user.avatar ? `${serverBaseUrl()}/${user.avatar}` : userpic;
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <Row className="show-grid">
          <div
            className="isoDropdownLink"
            style={{ borderBottom: "1px solid #e8e8e8" }}
          >
            <Col sm={12} md={6}>
              <img
                alt="user"
                src={userImage}
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Col>
            <Col sm={6} md={18}>
              <Link
                onClick={() => {
                  this.handleVisibleChange();
                }}
                to={`/dashboard/profile/${user._id}`}
                className="user-profile-link"
              >
                <p style={{ marginBottom: "3px" }}>
                  <strong>
                    {user.firstName} {user.lastName}
                  </strong>
                </p>
                <p style={{ marginBottom: "3px" }}>
                  {user.email.length <= 18
                    ? user.email
                    : user.email.substring(0, 18) + "..."}
                </p>
              </Link>
            </Col>
          </div>
        </Row>
        <a className="isoDropdownLink" onClick={this.props.logout}>
          Logout
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      // <Popover
      //   content={content}
      //   trigger="click"
      //   visible={this.state.visible}
      //   onVisibleChange={this.handleVisibleChange}
      //   arrowPointAtCenter={true}
      //   placement="bottomLeft"
      // >
      // <div className="isoImgWrapper">
      //   <img alt="user" src={userImage} />
      //   <span className="userActivity online" />
      // </div>
      // </Popover>
      <Tooltip placement="right" title={"Logout"} className="tooltip">
        <div className="isoImgWrapper" onClick={this.props.logout}>
          <img
            alt="user"
            src={require("../../../src/image/logout.svg").default}
            style={{ width: "25px", height: "25px" }}
          />
        </div>
      </Tooltip>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: getProfile(),
});

export default connect(
  mapStateToProps,
  { logout }
)(TopbarUser);
