const actions = {
  FETCH_CLASSROOM_ADMIN: "FETCH_CLASSROOM_ADMIN",
  FETCH_CLASSROOM_ADMIN_SUCCESS: "FETCH_CLASSROOM_ADMIN_SUCCESS",
  FETCH_CLASSROOM_ADMIN_DETAILS: "FETCH_CLASSROOM_ADMIN_DETAILS",
  FETCH_CLASSROOM_ADMIN_DETAILS_SUCCESS:
    "FETCH_CLASSROOM_ADMIN_DETAILS_SUCCESS",
  SAVE_CLASSROOM_ADMIN: "SAVE_CLASSROOM_ADMIN",
  DELETE_CLASSROOM_STUDENTS: "DELETE_CLASSROOM_STUDENTS",
  DELETE_CLASSROOM_ADMIN: "DELETE_CLASSROOM_ADMIN",
  DELETE_CLASSROOM_ADMIN_SUCCESS: "DELETE_CLASSROOM_ADMIN_SUCCESS",
  EDIT_SINGLE_CLASSROOM_ADMIN: "EDIT_SINGLE_CLASSROOM_ADMIN",
  EDIT_SINGLE_CLASSROOM_ADMIN_SUCCESS: "EDIT_SINGLE_CLASSROOM_ADMIN_SUCCESS",
  SET_FILTER: "SET_FILTER",
  RESET: "RESET",
  fetchClassroomAdmins: (spinHandler) => ({
    type: actions.FETCH_CLASSROOM_ADMIN,
    spinHandler,
  }),

  fetchSingleClassroomAdmin: (classroom_id, spinHandler) => ({
    type: actions.FETCH_CLASSROOM_ADMIN_DETAILS,
    classroom_id,
    spinHandler,
  }),
  saveClassroomAdmin: (payload, classroom_id, cb) => ({
    type: actions.SAVE_CLASSROOM_ADMIN,
    payload,
    classroom_id,
    cb,
  }),
  deleteClassroomStudents: (payload, classroom_id, cb) => ({
    type: actions.DELETE_CLASSROOM_STUDENTS,
    payload,
    classroom_id,
    cb,
  }),
  deleteClassroomAdmin: (payload, classroom_id, spinHandler) => ({
    type: actions.DELETE_CLASSROOM_ADMIN,
    payload,
    classroom_id,
    spinHandler,
  }),
  editSingleClassroomAdmin: (classroom_id, payload, spinHandler) => ({
    type: actions.EDIT_SINGLE_CLASSROOM_ADMIN,
    classroom_id,
    payload,
    spinHandler,
  }),
  setFilter: (key) => ({
    type: actions.SET_FILTER,
    key,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
};
export default actions;
