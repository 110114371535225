import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* config() {
  yield takeEvery(actions.FETCH_CONFIG_ZOOM, function*(action) {
    try {
      const roles = yield call(API.common.roles);
      const schools = yield call(API.common.schools);
      // const grades = yield call(API.common.grades);
      // const subjects = yield call(API.subjects.list);
      yield put(
        actions.fetchConfigSuccess({
          roles: roles && roles.data.roles ? roles.data.roles : [],
          schools: schools && schools.data.schools ? schools.data.schools : [],
          grades: /*grades && grades.data.grades ? grades.data.grades : */ [],
          subjects: [],
          // subjects && subjects.data.subjects ? subjects.data.subjects : [],
        })
      );
      yield call(action.callBack);
    } catch (err) {
      yield call(action.callBack);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}
export function* fetchUserList() {
  yield takeEvery(actions.FETCH_USER_LIST_ZOOM, function*(action) {
    try {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        if (payload[key] === undefined) {
          delete payload[key];
        }
      });

      const resp = yield call(API.psm.list, action.payload);
      if (!resp.data) {
        Notification("error", "No users found for this combination!");
      }

      yield put(
        actions.fetchUserListSuccess(
          resp.data
            ? resp.data.map((item) => ({
                _id: item._id,
                name: `${item.firstName} ${item.lastName}`,
              }))
            : []
        )
      );
      yield call(action.callBack);
    } catch (err) {
      yield call(action.callBack);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* save() {
  yield takeEvery(actions.SAVE_ZOOM_MEETING, function*(action) {
    try {
      const resp = yield call(API.zoom.save, action.payload);
      if (resp.data.message === "Success") {
        Notification("success", "Zoom meeting added");
      }
      yield call(action.callBack);
    } catch (err) {
      yield call(action.callBack);
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(config)]);
  yield all([fork(fetchUserList)]);
  yield all([fork(save)]);
}
