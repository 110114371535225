const actions = {
  GET_ALL_MCQ_TESTS: "GET_ALL_MCQ_TESTS",
  GET_ALL_MCQ_TESTS_SUCCESS: "GET_ALL_MCQ_TESTS_SUCCESS",
  GET_ALL_SUBJECTS: "GET_ALL_SUBJECTS",
  GET_ALL_SUBJECTS_SUCCESS: "GET_ALL_SUBJECTS_SUCCESS",
  GET_ALL_CLASSES: "GET_ALL_CLASSES",
  GET_ALL_CLASSES_SUCCESS: "GET_ALL_CLASSES_SUCCESS",
  GET_ALL_SCHOOLS: "GET_ALL_SCHOOLS",
  GET_ALL_SCHOOLS_SUCCESS: "GET_ALL_SCHOOLS_SUCCESS",
  UPLOAD_TEST_DETAILS: "UPLOAD_TEST_DETAILS",
  UPLOAD_TEST_DETAILS_SUCCESS: "UPLOAD_TEST_DETAILS_SUCCESS",
  UPLOAD_ASSERTION_FILE: "UPLOAD_ASSERTION_FILE",
  UPLOAD_ASSERTION_FILE_SUCCESS: "UPLOAD_ASSERTION_FILE_SUCCESS",
  EDIT_TEST_DETAILS: "EDIT_TEST_DETAILS",
  EDIT_TEST_DETAILS_SUCCESS: "EDIT_TEST_DETAILS_SUCCESS",
  DELETE_TEST: "DELETE_TEST",
  SET_MCQ_LOADING: "SET_MCQ_LOADING",
  SET_TABLE_LOADING: "SET_TABLE_LOADING",
  SET_MCQ_ERROR: "SET_MCQ_ERROR",
  GET_TEST_REPORT: "GET_TEST_REPORT",
  SET_TEST_REPORT: "SET_TEST_REPORT",
  GET_LESSON_REPORT: "GET_LESSON_REPORT",
  GET_LESSON_REPORT_SUCCESS: "GET_LESSON_REPORT_SUCCESS",
  getAllMCQ: (spinHandler) => ({
    type: actions.GET_ALL_MCQ_TESTS,
    spinHandler,
  }),
  getAllSubjects: (spinHandler) => ({
    type: actions.GET_ALL_SUBJECTS,
    spinHandler,
  }),
  getAllClasses: (spinHandler) => ({
    type: actions.GET_ALL_CLASSES,
    spinHandler,
  }),
  getAllSchools: (spinHandler) => ({
    type: actions.GET_ALL_SCHOOLS,
    spinHandler,
  }),
  uploadTestDetails: (payload) => ({
    type: actions.UPLOAD_TEST_DETAILS,
    payload,
  }),
  uploadAssertionFile: (payload) => ({
    type: actions.UPLOAD_ASSERTION_FILE,
    payload,
  }),
  editTestDetails: (payload) => ({
    type: actions.EDIT_TEST_DETAILS,
    payload,
  }),
  deleteMCQTest: (payload, cb) => ({
    type: actions.DELETE_TEST,
    payload,
    cb,
  }),
  getReport: (payload) => ({
    type: actions.GET_TEST_REPORT,
    payload,
  }),
  fetchLessonReport: (payload) => ({
    type: actions.GET_LESSON_REPORT,
    payload,
  }),
};
export default actions;
