import { fromJS } from "immutable";
import actions from "./actions";

const initState = fromJS({
  lessons: [],
  lessonDetail: null,
  loading: false,
  errors: null,
  libraries: null,
});

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_LESSON_DETAILS:
      return state
        .set("lessonDetail", null)
        .set("loading", true)
        .set("errors", null);
    case actions.FETCH_LESSON_DETAILS_SUCCESS:
      return state
        .set("lessonDetail", action.data || null)
        .set("loading", false);
    case actions.FETCH_LESSON_DETAILS_ERROR:
      return state.set("lessonDetail", null).set("loading", false);
    case actions.FETCH_LESSONS:
      return state
        .set("lessons", [])
        .set("loading", true)
        .set("errors", null);
    case actions.FETCH_LESSONS_SUCCESS:
      return state.set("lessons", action.data || []).set("loading", false);
    case actions.SAVE_LESSON:
      return state.set("loading", true).set("errors", null);
    case actions.SAVE_LESSON_SUCCESS:
      return state
        .set("loading", false)
        .set("lessonDetail", action.data || null);
    case actions.SAVE_LESSON_ERROR:
      return state.set("loading", false).set("errors", action.errors);
    case actions.DELETE_LESSON:
      return state.set("loading", true).set("errors", null);
    case actions.DELETE_LESSON_SUCCESS:
      return state
        .set("loading", false)
        .set("lessonDetail", action.data || null);
    case actions.DELETE_LESSON_ERROR:
      return state.set("loading", false).set("errors", action.errors);
    case actions.FETCH_LIBRARY:
      return state.set("loading", true).set("errors", null);
    case actions.FETCH_LIBRARY_SUCCESS:
      return state.set("loading", false).set("libraries", action.data);
      case actions.FETCH_MY_LIBRARY:
      return state.set("loading", true).set("errors", null);
    case actions.FETCH_MY_LIBRARY_SUCCESS:
      return state.set("loading", false).set("myLibraries", action.data);
    case actions.FILE_UPLOAD:
      return state.set("loading", true).set("errors", null);
    case actions.FILE_UPLOAD_SUCCESS:
      return state.set("loading", false);
    case actions.FILE_UPLOAD_ERROR:
      return state.set("loading", false);
    default:
      return state;
  }
}
