import { all, call, takeEvery, put, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import actions from "./actions";
import API from "../../helpers/API";
import Notification from "../../components/notification";

export function* fetchOrganizations() {
  yield takeEvery(actions.FETCH_ORGANIZATIONS, function*(action) {
    try {
      const resp = yield call(API.organizations.list);
      yield put({
        type: actions.FETCH_ORGANIZATIONS_SUCCESS,
        organizations: resp.data.data,
      });
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      // Notification(
      //   "error",
      //   err && err.error
      //     ? err.error.message
      //     : "Session timed out. Please login again"
      // );
    }
  });
}

export function* fetchSingleOrganization() {
  yield takeEvery(actions.FETCH_ORGANIZATION_DETAILS, function*(action) {
    try {
      const resp = yield call(API.organizations.details, action.organizationId);
      yield put({
        type: actions.FETCH_ORGANIZATION_DETAILS_SUCCESS,
        organization: resp.data,
      });
    } catch (err) {
      yield call(action.spinHandler, false);
      // Notification(
      //   "error",
      //   err && err.error
      //     ? err.error.message
      //     : "Session timed out. Please login again"
      // );
    }
  });
}

export function* saveOrganization() {
  yield takeEvery(actions.SAVE_ORGANIZATION, function*(action) {
    try {
      let resp, message;
      let data = new FormData();
      if (action?.payload?.iconList?.originFileObj) {
        data.append(
          "icon",
          action.payload.iconList.originFileObj
            ? action.payload.iconList.originFileObj
            : ""
        );
      }
      if (action?.payload?.file) {
        if (action?.payload?.file?.originFileObj)
          data.append("file", action?.payload?.file?.originFileObj);
      } else data.append("file", "");

      data.append("name", action.payload.name);
      data.append("address", action.payload.address);
      data.append("contact", action.payload.contact);
      data.append("email", action.payload.email);
      if (action.organizationId) {
        yield call(API.organizations.update, data, action.organizationId);
        message = "Organization updated successfully";
      } else {
        yield call(API.organizations.add, data);
        message = "Organization created successfully";
      }
      const organizations = yield call(API.organizations.list);
      yield put({
        type: actions.FETCH_ORGANIZATIONS_SUCCESS,
        organizations: organizations.data.data,
      });
      yield call(action.cb);
      Notification("success", message);
    } catch (err) {
      yield call(action.cb, "loader");
      Notification(
        "error",
        err && err.error
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export function* deleteOrganization() {
  yield takeEvery(actions.DELETE_ORGANIZATION, function*(action) {
    try {
      yield call(API.organizations.delete, action.organizationId);
      const resp = yield call(API.organizations.list);
      yield put({
        type: actions.FETCH_ORGANIZATIONS_SUCCESS,
        organizations: resp.data.data,
      });
      Notification("success", "Organization deleted successfully");
      yield call(action.spinHandler, false);
    } catch (err) {
      yield call(action.spinHandler, false);
      Notification(
        "error",
        err && err.error && err.error.message
          ? err.error.message
          : "Session timed out. Please login again"
      );
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchOrganizations),
    fork(saveOrganization),
    fork(deleteOrganization),
    fork(fetchSingleOrganization),
  ]);
}
