import findIndex from "lodash/findIndex";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { menuList } from "../../helpers/utility";
import {
  getBlockedMenuList,
  getProfile,
  getRole,
  getUserClassroom,
} from "../../redux/auth/selectors";
import menu from "../Sidebar/menuConfig";
import UnAuthorized from "./UnAuthorized";

const SecurityFile = ({
  children,
  profile,
  blockList,
  userRole,
  classrooms,
}) => {
  const [hasPermission, setHasPermission] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const list = menuList(menu["content"], blockList, userRole, classrooms);
  const menulist=['profile','dashboardNew']
  useEffect(
    () => {
      const path = window.location.pathname.split("/")[2];
      if (menulist.includes(path)) {
        setHasPermission(true);
        setUnauthorized(false);
      } else if (window.location.pathname == "/dashboard")
        if (profile?.hideAdminDashboard) {
          const route =
            list[0]?.key != "dashboard" ? list[0]?.path : list[1]?.path;
          window.location.href = `/dashboard${route}`;
          setHasPermission(true);
          setUnauthorized(false);
        } else {
          setHasPermission(true);
          setUnauthorized(false);
        }
      else {
        const index = findIndex(list, ["path", `/${path}`]);
        if (index > -1) {
          setHasPermission(true);
          setUnauthorized(false);
        } else {
          setHasPermission(false);
          setUnauthorized(true);
        }
      }
    },
    [window.location.pathname]
  );

  if (hasPermission) return children;
  if (unauthorized) return <UnAuthorized />;
  return null;
};

const mapStateToProps = createStructuredSelector({
  blockList: getBlockedMenuList(),
  profile: getProfile(),
  userRole: getRole(),
  classrooms: getUserClassroom(),
});

export default connect(
  mapStateToProps,
  null
)(SecurityFile);
