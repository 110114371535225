const actions = {
  FETCH_DEVICES: "FETCH_DEVICES",
  FETCH_DEVICES_SUCCESS: "FETCH_DEVICES_SUCCESS",
  SAVE_DEVICE: "SAVE_DEVICE",
  DELETE_DEVICE: "DELETE_DEVICE",
  FETCH_DEVICE_CONFIGS: "FETCH_DEVICE_CONFIGS",
  FETCH_DEVICE_CONFIGS_SUCCESS: "FETCH_DEVICE_CONFIGS_SUCCESS",
  FETCH_DEVICE_USERS: "FETCH_DEVICE_USERS",
  FETCH_DEVICE_USERS_SUCCESS: "FETCH_DEVICE_USERS_SUCCESS",
  ASSIGN_USER: "ASSIGN_USER",
  UNASSIGN_USER: "UNASSIGN_USER",
  STATUS_DEVICE: "STATUS_DEVICE",
  STATUS_DEVICE_SUCCESS: "STATUS_DEVICE_SUCCESS",
  SET_FILTER: "SET_DEVICE_FILTER",
  RESET: "RESET",
  UN_PIN_MULTIPLE: "UN_PIN_MULTIPLE",
  DEVICES_SUMMARY: "FETCH_DEVICES_SUMMARY",
  FETCH_DEVICE_STATUS: "FETCH_DEVICE_STATUS",
  DEVICE_CLEANUP: "DEVICE_CLEANUP",
  DEVICE_CLEANUP_SUCCESS: "DEVICE_CLEANUP_SUCCESS",
  fetchDevices: (spinHandler) => ({
    type: actions.FETCH_DEVICES,
    spinHandler,
  }),

  fetchSummary: (payload, spinHandler) => ({
    type: actions.FETCH_DEVICE_STATUS,
    payload,
    spinHandler,
  }),
  spaceUpDevice: (payload, cb) => ({
    type: actions.DEVICE_CLEANUP,
    payload,
    cb,
  }),
  saveDevice: (payload, deviceId, cb) => ({
    type: actions.SAVE_DEVICE,
    payload,
    deviceId,
    cb,
  }),
  deleteDevice: (payload, deviceId, spinHandler) => ({
    type: actions.DELETE_DEVICE,
    payload,
    deviceId,
    spinHandler,
  }),
  fetchDeviceConfigs: () => ({
    type: actions.FETCH_DEVICE_CONFIGS,
  }),
  fetchDeviceUsers: (payload, callback = () => {}) => ({
    type: actions.FETCH_DEVICE_USERS,
    payload,
    callback,
  }),
  assignUser: (payload, deviceId, cb) => ({
    type: actions.ASSIGN_USER,
    payload,
    deviceId,
    cb,
  }),
  unAssignUser: (payload, deviceId, cb) => ({
    type: actions.UNASSIGN_USER,
    payload,
    deviceId,
    cb,
  }),
  changeDeviceStatus: (payload, deviceId, cb) => ({
    type: actions.STATUS_DEVICE,
    payload,
    deviceId,
    cb,
  }),
  setFilter: (key) => ({
    type: actions.SET_FILTER,
    key,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
  unPinMultiple: (ids, callBack) => ({
    type: actions.UN_PIN_MULTIPLE,
    payload: { ids, callBack },
  }),
};
export default actions;
