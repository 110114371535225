import App from "./app/reducer";
import Appversion from "./appVersions/reducer";
import Otherappversion from "./chromeVersions/reducer";
import Auth from "./auth/reducer";
import Books from "./books/reducer";
import Devices from "./devices/reducer";
import Group from "./chatgroups/reducer";
import Grades from "./grades/reducer";
import Import from "./imports/reducer";
import Notification from "./notifications/reducer";
import Psm from "./psm/reducer";
import Rewards from "./rewards/reducer";
import Schools from "./schools/reducer";
import Organizations from "./organizations/reducer";
import Courses from "./Courses/reducer";
import DecryptedPath from "./Courses/reducer";
import Students from "./Courses/reducer";
import Classrooms from "./MyClassroom/reducer";
import ClassroomAdmins from "./classroomAdmin/reducer";
import Teachers from "./Courses/reducer";
import ClassroomTeachers from "./Courses/reducer";
import Subjects from "./subjects/reducer";
import Survey from "./surveys/reducer";
import Test from "./test/reducer";
import Users from "./users/reducer";
import Websites from "./websites/reducer";
import Role from "./role/reducer";
import Admin from "./admin/reducer";
import Health from "./health/reducer";
import dashboard from "./dashboard/reducer";
import Zoom from "./zoom/reducer";
import bigBlue from "./bigBlue/reducer";
import lessonBuilder from "./lessonBuilder/reducer";
import area from "./area/reducer";
import type from "./Type/reducer";
export default {
  App,
  Appversion,
  Otherappversion,
  Auth,
  Books,
  Devices,
  Grades,
  Group,
  Import,
  Notification,
  Psm,
  Rewards,
  Schools,
  Organizations,
  Courses,
  DecryptedPath,
  Students,
  Classrooms,
  ClassroomAdmins,
  Teachers,
  ClassroomTeachers,
  Subjects,
  Survey,
  Test,
  Users,
  Websites,
  Role,
  Admin,
  dashboard,
  Health,
  Zoom,
  bigBlue,
  lessonBuilder,
  area,
  type,
};
