const actions = {
  FETCH_BOOKS: "FETCH_BOOKS",
  FETCH_BOOKS_SUCCESS: "FETCH_BOOKS_SUCCESS",
  SAVE_BOOK: "SAVE_BOOK",
  ADD_BOOK_VERSION: "ADD_BOOK_VERSION",
  ADD_BOOK_VERSION_SUCCESS: "ADD_BOOK_VERSION_SUCCESS",
  ADD_BOOK_VERSION_LIBRARY: "ADD_BOOK_VERSION_LIBRARY",
  ADD_BOOK_VERSION_LIBRARY_SUCCESS: "ADD_BOOK_VERSION_LIBRARY_SUCCESS",
  DELETE_BOOK_VERSION: "DELETE_BOOK_VERSION",
  DELETE_BOOK: "DELETE_BOOK",
  FETCH_BOOK_DETAILS: "FETCH_BOOK_DETAILS",
  FETCH_BOOK_DETAILS_SUCCESS: "FETCH_BOOK_DETAILS_SUCCESS",
  FETCH_BOOK_CONFIGS: "FETCH_BOOK_CONFIGS",
  FETCH_BOOK_CONFIGS_SUCCESS: "FETCH_BOOK_CONFIGS_SUCCESS",
  FETCH_BONUS_BOOKS: "FETCH_BONUS_BOOKS",
  FETCH_BONUS_BOOKS_SUCCESS: "FETCH_BONUS_BOOKS_SUCCESS",
  SAVE_BONUS_BOOK: "SAVE_BONUS_BOOK",
  ADD_BONUS_BOOK_VERSION: "ADD_BONUS_BOOK_VERSION",
  DELETE_BONUS_BOOK_VERSION: "DELETE_BONUS_BOOK_VERSION",
  DELETE_BONUS_BOOK: "DELETE_BONUS_BOOK",
  FETCH_BONUS_BOOK_DETAILS: "FETCH_BONUS_BOOK_DETAILS",
  FETCH_BONUS_BOOK_DETAILS_SUCCESS: "FETCH_BONUS_BOOK_DETAILS_SUCCESS",
  FETCH_BONUS_BOOK_CONFIGS: "FETCH_BONUS_BOOK_CONFIGS",
  FETCH_BONUS_BOOK_CONFIGS_SUCCESS: "FETCH_BONUS_BOOK_CONFIGS_SUCCESS",
  FETCH_BONUS_CONTENT_USERS: "FETCH_BONUS_CONTENT_USERS",
  FETCH_BONUS_CONTENT_USERS_SUCCESS: "FETCH_BONUS_CONTENT_USERS_SUCCESS",
  FETCH_SCHOOLS_SUCCESS: "FETCH_SCHOOLS_SUCCESS",
  FETCH_SCHOOLS: "FETCH_SCHOOLS_BOOK",
  FETCH_ORGANIZATIONS_SUCCESS: "FETCH_ORGANIZATIONS_SUCCESS",
  FETCH_ORGANIZATIONS: "FETCH_ORGANIZATIONS",
  SET_FILTER: "SET_FILTER",
  RESET: "RESET",
  GET_USER_RECORD: "GET_USER_RECORD",
  GET_USER_RECORD_BONUS: "GET_USER_RECORD_BONUS",
  FETCH_LESSON_TESTS: "FETCH_LESSON_TESTS",
  FETCH_LESSON_TESTS_SUCCESS: "FETCH_LESSON_TESTS_SUCCESS",

  getUsersRecordBonus: (id, callBack) => ({
    type: actions.GET_USER_RECORD_BONUS,
    id,
    callBack,
  }),
  getUsersRecord: (id, callBack) => ({
    type: actions.GET_USER_RECORD,
    id,
    callBack,
  }),
  fetchBooks: (payload, spinHandler) => ({
    type: actions.FETCH_BOOKS,
    payload,
    spinHandler,
  }),
  saveBook: (payload, bookId, cb) => ({
    type: actions.SAVE_BOOK,
    payload,
    bookId,
    cb,
  }),
  addBookFileLibrary: (payload, bookId, spinHandler) => ({
    type: actions.ADD_BOOK_VERSION_LIBRARY,
    payload,
    bookId,
    spinHandler,
  }),
  addBookFile: (payload, bookId, spinHandler) => ({
    type: actions.ADD_BOOK_VERSION,
    payload,
    bookId,
    spinHandler,
  }),
  deleteBook: (payload, bookId, spinHandler) => ({
    type: actions.DELETE_BOOK,
    payload,
    bookId,
    spinHandler,
  }),
  deleteBookVersion: (bookId, versionId, spinHandler) => ({
    type: actions.DELETE_BOOK_VERSION,
    bookId,
    versionId,
    spinHandler,
  }),
  fetchSingleBook: (bookId, spinHandler) => ({
    type: actions.FETCH_BOOK_DETAILS,
    bookId,
    spinHandler,
  }),
  fetchBookConfigs: (spinHandler) => ({
    type: actions.FETCH_BOOK_CONFIGS,
    spinHandler,
  }),
  fetchBonusBooks: (payload, spinHandler) => {
    return {
      type: actions.FETCH_BONUS_BOOKS,
      payload,
      spinHandler,
    };
  },
  saveBonusBook: (payload, bookId, cb) => ({
    type: actions.SAVE_BONUS_BOOK,
    payload,
    bookId,
    cb,
  }),
  addBonusBookVersion: (payload, bookId, spinHandler) => ({
    type: actions.ADD_BONUS_BOOK_VERSION,
    payload,
    bookId,
    spinHandler,
  }),
  deleteBonusBook: (payload, bookId, spinHandler) => ({
    type: actions.DELETE_BONUS_BOOK,
    payload,
    bookId,
    spinHandler,
  }),
  deleteBonusBookVersion: (resourceType, bookId, versionId, spinHandler) => ({
    type: actions.DELETE_BONUS_BOOK_VERSION,
    resourceType,
    bookId,
    versionId,
    spinHandler,
  }),
  fetchBonusContent: (bookId, spinHandler) => ({
    type: actions.FETCH_BONUS_BOOK_DETAILS,
    bookId,
    spinHandler,
  }),
  fetchBonusContentUsers: (payload, spinHandler, school = undefined) => ({
    type: actions.FETCH_BONUS_CONTENT_USERS,
    payload,
    spinHandler,
    school,
  }),
  setFilter: (key) => ({
    type: actions.SET_FILTER,
    key,
  }),
  reset: () => ({
    type: actions.RESET,
  }),
  fetchSchools: () => ({
    type: actions.FETCH_SCHOOLS,
  }),
  fetchOrganizations: () => ({
    type: actions.FETCH_ORGANIZATIONS,
  }),
  fetchLessonTests: (payload) => ({
    type: actions.FETCH_LESSON_TESTS,
    payload,
  }),
};
export default actions;
